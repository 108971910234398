.accordionsec {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  // height: 50vh;
  height: 100%;
  .acc_container {
    position: relative;
    top: 5%;
    width: 100%;
    .acc_wrap {
      padding: 2.438em 0 1.5em 0;
      color: black;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      text-align: center;
      cursor: pointer;
      border-top: 0.5px solid rgba(112, 112, 112, 0.5);
    }
    .title {
      line-height: 1.35em;
      margin-bottom: 0.255em;
      font-family: "HelveticaNeueBold";
      font-size: 1.572em;
      letter-spacing: 0.48px;
      color: #1a1818;
      font-style: initial;
      opacity: 1;
      font-weight: bold;
      text-align: left;
    }
    .acc_dropdown {
      max-width: 95%;
      font-size: 1.134em;
      font-style: initial;
      letter-spacing: 0.4px;
      color: #1a1818;
      opacity: 1;
      font-weight: 400;
      font-family: "HelveticaNeueBold";
      padding-left: 2em;
      padding-bottom: 0.5em;
      -webkit-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
    }
    .acc_question {
      font-size: 1.26em;
      font-style: initial;
      font-family: "HelveticaNeueBold";
      font-weight: 500;
      // border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
      padding-top: 0.5em;
    }
    .acc_p {
      font-weight: normal;
      font-family: "Arimo", sans-serif;
      padding-bottom: 1.5em;
      font-size: 1.134em;
      font-style: initial;
    }
    .acc_span {
      margin-top: 0.3em;
      margin-right: 2.5em;
      .minus_icon,
      .plus_icon {
        max-width: 2em;
      }
    }
    a {
      outline: none;
      border: none;
      text-decoration: none;
      color: #ce0024;
      font-size: 1em;
    }
  }
}

.faqpagesec1 {
  margin-top: 18.813em;
  padding: 0 8.75em 9.094em 8.75em;
  .heading {
    font-size: 3.438em;
    font-family: DVBTTBhimaENBold;
    letter-spacing: 0;
    color: #1a1818;
    text-transform: uppercase;
    opacity: 1;
    font-weight: bold;
  }
  .imgsym {
    width: 3.5em;
    height: 1.3em;
    margin-bottom: 4em;
  }
  .line {
    border-bottom: 0.5px solid #707070;
    opacity: 0.5;
  }
}
@media (max-width: 834px) {
  .faqpagesec1 {
    margin-top: 12.813em;
    padding: 0 1.5em 2em 1.5em;
  }
  .accordionsec {
    .acc_container {
      .acc_dropdown {
        font-size: 1.065em;
      }
      .acc_question {
        font-size: 1.41em;
      }
      .title {
        font-size: 1.6em;
      }
      .acc_p {
        font-size: 1.32em;
      }
      a {
        font-size: 1em;
      }
    }
  }
}