.diwali_about_sec {
  background-image: url("../../../images/Diwali/Golden-Jublee-banner-bg@2x.jpg");
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  // object-fit: contain;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;
  z-index: -9;
  .background_img {
    position: relative;
    margin-top: 9em;
    padding-top: 10em;
    //   background-image: url("../../../images/Diwali/about-background.png");
    // background-size: contain;
    //   background-size: cover;
    //   background-repeat: no-repeat;
    margin: 0 7.188em 0;
    filter: drop-shadow(0 0 1.75em black);
    .about_back {
      width: 100%;
      position: absolute;
      z-index: -1;
    }
    .text_container {
      padding-top: 8em;
      margin: 0 7.188em 0;
      max-width: 46%;
      .about_heading {
        text-align: left;
        font: normal normal bold 3.75em/64px DVB-TTBhimaEN;
        letter-spacing: 0px;
        color: #ba131a;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 0.3em;
      }
      .about_desc {
        text-align: left;
        font-size: 1.25em;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        letter-spacing: 0.4px;
        color: #141414;
        opacity: 1;
        margin-bottom: 2.25em;
      }
      .gofor_btn {
        display: block;
        width: 100%;
        max-width: 11.438em;
        font-size: 1.625em;
        padding: 20px 0;
        color: #fff;
        height: auto;
        border: none;
        background-color: #d10016;
        font-family: "DVB-TTBhimaEN";
        box-shadow: 13px 17px 7px #00000029;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
        z-index: 2;
        &:before {
          content: "";
          display: inline-block;
          transform: translateY(50%);
          left: 0;
          position: absolute;
          border-radius: 50%;
          background-color: #740003;
          z-index: -1;
          transition: transform ease 1s;
        }
        &:before {
          content: "";
          display: inline-block;
          transform: translateY(50%);
          left: -1em;
          position: absolute;
          width: 14em;
          height: 8em;
          border-radius: 50%;
          background-color: #740003;
          z-index: -1;
          transition: transform ease 1s;
        }
        &:hover {
          box-shadow: none;
          &:before {
            transform: translateY(-45%);
          }
          color: white;
          transition: color 0.1s ease;
          transition-delay: 0.2s;
        }
      }
    }
  }
}

@media (min-width: 2000px) {
  .diwali_about_sec {
    .background_img {
      .text_container {
        max-width: 1900px;
        width: 100%;
        padding: 0 15px;
        max-width: 55%;
        margin: 10em 10em 0;
      }
    }
  }
}
@media (max-width: 1100px) {
  .diwali_about_sec {
    .background_img {
      margin: 0 4.188em 0;
    }
    .text_container {
      .gofor_btn {
        height: auto;
      }
    }
  }
}
@media (max-width: 992px) {
  .diwali_about_sec {
    .background_img {
      .text_container {
        padding-top: 2em;
        margin: 0 2em 0;
        max-width: 60%;
        .about_desc {
          margin-bottom: 0.25em;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .diwali_about_sec {
    // overflow-y: hidden;
    background-position: bottom;
    max-height: 100vh;
    .background_img {
      padding-top: 10em;
      margin: 0 15px;
      .text_container {
        padding-top: 5em;
        margin: 0 15px;
        max-width: 100%;
        .about_heading {
          margin-bottom: 0;
          font-size: 2.4em;
          line-height: 1.5em;
          margin-bottom: 5px;
        }
        .about_desc {
          font-size: 14px;
          margin-bottom: 2.25em;
        }
        .gofor_btn {
          height: auto;
          font-size: 14px;
          width: 100%;
          min-width: 10em;
        }
      }
    }
  }
}
