@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
.termssec {
  font-family: "Poppins", sans-serif;
  margin-top: 10em;
  padding-bottom: 15em;
  position: relative;
  .herelink {
    color: #cc121d;
    font-weight: bold;
  }
  .laxmilogo_wrapper {
    position: absolute;
    top: -7em;
    left: 4em;
  }
  .laxmilogo {
    max-width: 8.438em;
  }
  .terms_h1 {
    text-align: center;
    font-size: 3.125em;
    letter-spacing: 0.2px;
    line-height: 1.2em;
    font-weight: 300;
    text-transform: uppercase;
    font-family: "Cinzel", serif;
    color: #1a1818;
  }
  .terms_desc {
    font-size: 1.15em;
    color: #1a1818;
    letter-spacing: 0.4px;
    line-height: 1.9em;
    font-weight: 400;
  }
  .no_purchase {
    font-weight: 600;
    margin-top: 3em;
  }
  .terms_para_heading {
    font-size: 1.7em;
    letter-spacing: 0.25px;
    line-height: 1.591em;
    color: #1f1e1e;
    font-weight: 600;
    font-family: "Cinzel", serif;
  }
  .block_wrapper {
    margin-top: 2em;
  }
  .enter_step_wrapper {
    // font-size: 1.15em;
    // color: #1a1818;
    // letter-spacing: 0.4px;
    // line-height: 1.9em;
    span {
      font-weight: 600;
    }
  }
  .terms_table {
    margin-bottom: 1em;
    text-align: center;
    border: 1px solid #1a1818;
    td,
    th {
      outline: 1px solid #1a1818;
      padding: 0.7em 1.5em;
    }
  }
  .sub_head {
    font-weight: 600;
    margin-bottom: 0.5em;
  }
  .span_tag {
    font-weight: 600;
  }
}
@media (max-width: 767px) {
  .termssec {
    ul {
      padding-left: 1.5em;
    }
    .laxmilogo_wrapper {
      top: -7em;
      left: 1em;
    }
    .laxmilogo {
      max-width: 5em;
    }
    .terms_h1 {
      font-size: 2.7em;
    }
    .no_purchase {
      font-size: 1.4em;
      margin-top: 2em;
    }
    .terms_desc {
      font-size: 1.4em;
    }
    .terms_para_heading {
      font-size: 2em;
    }
    .table_wrapper {
      margin-bottom: 2em;
      //   overflow-x: auto;
    }
    // .table_wrapper::-webkit-scrollbar {
    //   width: 5rem !important;
    // }
    .terms_table {
      //   width: 40em;
    }
    .terms_table {
      th,
      td {
        text-align: center;
        padding-left: 0.3em;
        padding-right: 0.3em;
      }
    }
  }
}
