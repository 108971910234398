@font-face {
  font-family: "HelveticaNeueItalic";
  src: url("./fontsnew/HelveticaNeue-Italic.eot");
  src: url("./fontsnew/HelveticaNeue-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./fontsnew/HelveticaNeue-Italic.woff2") format("woff2"),
    url("./fontsnew/HelveticaNeue-Italic.woff") format("woff"),
    url("./fontsnew/HelveticaNeue-Italic.ttf") format("truetype"),
    url("./fontsnew/HelveticaNeue-Italic.svg#HelveticaNeue-Italic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "DVBTTBhimaENBold";
  src: url("./fontsnew/DVBTTBhimaENBold.eot");
  src: url("./fontsnew/DVBTTBhimaENBold.eot?#iefix") format("embedded-opentype"),
    url("./fontsnew/DVBTTBhimaENBold.woff2") format("woff2"),
    url("./fontsnew/DVBTTBhimaENBold.woff") format("woff"),
    url("./fontsnew/DVBTTBhimaENBold.ttf") format("truetype"),
    url("./fontsnew/DVBTTBhimaENBold.svg#DVBTTBhimaENBold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeueBoldItalic";
  src: url("./fontsnew/HelveticaNeue-BoldItalic.eot");
  src: url("./fontsnew/HelveticaNeue-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./fontsnew/HelveticaNeue-BoldItalic.woff2") format("woff2"),
    url("./fontsnew/HelveticaNeue-BoldItalic.woff") format("woff"),
    url("./fontsnew/HelveticaNeue-BoldItalic.ttf") format("truetype"),
    url("./fontsnew/HelveticaNeue-BoldItalic.svg#HelveticaNeue-BoldItalic")
      format("svg");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Arimo", sans-serif;
  src: url("./fontsnew/HelveticaNeue-Roman.eot");
  src: url("./fontsnew/HelveticaNeue-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("./fontsnew/HelveticaNeue-Roman.woff2") format("woff2"),
    url("./fontsnew/HelveticaNeue-Roman.woff") format("woff"),
    url("./fontsnew/HelveticaNeue-Roman.ttf") format("truetype"),
    url("./fontsnew/HelveticaNeue-Roman.svg#HelveticaNeue-Roman") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("./fontsnew/HelveticaNeue-Bold.eot");
  src: url("./fontsnew/HelveticaNeue-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./fontsnew/HelveticaNeue-Bold.woff2") format("woff2"),
    url("./fontsnew/HelveticaNeue-Bold.woff") format("woff"),
    url("./fontsnew/HelveticaNeue-Bold.ttf") format("truetype"),
    url("./fontsnew/HelveticaNeue-Bold.svg#HelveticaNeue-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1919px) {
  body {
    font-size: 14px;
  }
  .my_container {
    max-width: 1298px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1440px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: 1168px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 12px;
  }
  .my_container {
    max-width: 1094px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 1019px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 834px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: 712px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 10px;
  }
}

body {
  font-family: "Arimo", sans-serif;
  color: #1a1818;
  /* font-weight: 600; */
  /* font-weight: 600; */
}
