@import "../../App.scss";

.contest_wrapper {
  position: fixed;
  display: none;
  bottom: 5em;
  right: 0;
  z-index: 2;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 24px #ed1c24;
  .background_img_for_contest {
    max-width: 19.25em;
  }
  .contest_test_on_image {
    font-family: "Arimo", sans-serif;
    font-size: 2em;
    color: #ffffff;
    font-weight: bolder;
    position: absolute;
    top: 0.7em;
    width: 100%;
    bottom: 0;
    text-align: center;
    height: auto;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.contest_wrapper_mobile {
  display: none;
}

@media (min-width: 2000px) {
  .contest_wrapper {
    .contest_test_on_image {
      top: 0.5em;
    }
  }
}

@media (max-width: 600px) {
  .contest_wrapper {
    display: none;
  }
  .contest_wrapper_mobile {
    display: block;
    z-index: 2;
    position: fixed;
    bottom: -3.1em;
    right: 0;
    z-index: 9;
    border-radius: 20px 0 0 20px;
    .background_img_for_contest_mobile {
      max-width: 100%;
    }
    .contest_test_on_image_mobile {
      font-family: "Arimo", sans-serif;
      color: #ffffff;
      font-size: 2em;
      padding: 1.8em 1em;
      letter-spacing: 0.48px;
      font-weight: bolder;
      position: absolute;
      top: 0;
      width: 100%;
      bottom: 0;
      text-align: center;
      height: auto;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
