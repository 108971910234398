@import "../../../common.scss";

.form_sec {
  background-image: url("../../../images/Diwali/Golden-Jublee-banner-bg@2x.jpg");
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  // object-fit: contain;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  .form_background {
    position: relative;
    padding-top: 9em;
    .background_img {
      background-image: url("../../../images/Diwali/form-backgorund.png");
      // background-size: contain;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 0 5.188em 0;
      box-shadow: 0 0 1.75em black;
      border-top-left-radius: 3.5em;
      border-top-right-radius: 3.5em;
      height: 100%;
    }
    .form_wrapper {
      width: 100%;
      max-width: 91%;
      position: relative;
      padding: 3em 0 0 1.188em;
      .close_message {
        position: absolute;
        bottom: 4em;
        text-align: left;
        font-size: 1.125em;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        letter-spacing: 0.36px;
        opacity: 1;
      }
      .form_heading {
        text-align: left;
        font: normal normal bold 3.125em/64px DVB-TTBhimaEN;
        letter-spacing: 0px;
        color: #ba131a;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 0.5em;
      }
      .form_flex {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .form_field_customwidth {
          max-width: 31%;
        }
        .field_wtih_tooltip {
          position: relative;
          .coupon_box {
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #dcb35b;
          }
          .tooltip_icon {
            position: absolute;
            max-width: 2em;
            right: -3em;
            top: 50%;
            cursor: pointer;
          }
          .click-to-top span {
            font-size: 14px;
            display: none;
            position: absolute;
            padding: 1em 2em;
            top: -5em;
            right: -3em;
            left: 5em;
            background: #202020;

            border-radius: 5px;
            color: #fff;
          }

          .click-to-top:hover span {
            display: block;
            z-index: 1;
          }
        }
        .coupon_dummy {
          visibility: hidden;
          -webkit-appearance: none;
        }
        .radio_wrapper {
          padding: 0.5em 1em 0.5em 0em;
        }
        .mobile_no_wrapper {
          display: flex;
          align-items: flex-start;
          .country_code_select_wrapper {
            width: 100%;
            max-width: 20%;
            position: relative;
            cursor: pointer;
            margin-right: 0.5em;
            select {
              width: 100%;
              cursor: pointer;
              font-weight: 300;
              padding: 0.5em 1em 0.65em 0.5em;
              line-height: 1em;
              background-color: transparent;
              -webkit-appearance: none;
            }
            select:focus {
              border-bottom-color: #707070;
              background-color: transparent;
              -webkit-appearance: none;
            }
            .dropDownArrow {
              position: absolute;
              pointer-events: none;
              top: 40%;
              right: 0.5em;
              width: 100%;
              max-width: 1em;
            }
          }
          .dropDownArrow {
            position: absolute;
            pointer-events: none;
            top: 40%;
            right: 0.5em;
            width: 100%;
            max-width: 1em;
          }
        }

        .dropdown_field {
          position: relative;
          select {
            -webkit-appearance: none;
            color: #1a1818 !important;
          }
          .dropDownArrow {
            position: absolute;
            pointer-events: none;
            bottom: 15%;
            right: 1.2em;
            width: 100%;
            max-width: 1em;
          }
          .dropDownArrow2 {
            position: absolute;
            pointer-events: none;
            bottom: 18%;
            right: 1.2em;
            width: 100%;
            max-width: 1em;
          }
        }
      }

      .submit_btn {
        pointer-events: none;
        display: block;
        width: 100%;
        max-width: 11.438em;
        font-size: 1.625em;
        padding: 20px 0;
        color: #fff;
        height: 3em;
        border: none;
        // background-color: #d10016;
        background-color: #a0a0a0;
        font-family: "DVB-TTBhimaEN";
        box-shadow: 13px 17px 7px #00000029;
        position: relative;
        overflow: hidden;
        cursor: no-drop;
        transition: all 0.5s ease-in-out;
        z-index: 2;
        &:before {
          content: "";
          display: inline-block;
          transform: translateY(50%);
          left: 0;
          position: absolute;
          border-radius: 50%;
          background-color: #740003;
          z-index: -1;
          transition: transform ease 1s;
        }
        &:before {
          content: "";
          display: inline-block;
          transform: translateY(50%);
          left: -1em;
          position: absolute;
          width: 14em;
          height: 8em;
          border-radius: 50%;
          background-color: #740003;
          z-index: -1;
          transition: transform ease 1s;
        }
        // &:hover {
        //   box-shadow: none;
        //   transition: all 0.5s ease-in-out;
        //   &:before {
        //     transform: translateY(-45%);
        //   }
        //   color: white;
        //   transition: color 0.1s ease;
        //   transition-delay: 0.2s;
        // }
      }

      .checkbox_wrapper {
        position: relative;
        margin: 0.85em 0 0;
        max-width: 65%;
        .term_error {
          position: relative;
          font-size: 1.125em;
          font-weight: bold;
          color: red !important;
          margin-left: 3.5em;
          &:first-letter {
            text-transform: uppercase;
          }
        }
        .checkbox-div2.active {
          opacity: 1;
        }
        .checkbox-div2 {
          display: block;
          margin-bottom: 0;
          font-size: 0.87em;
          .checkbox_link {
            color: #1a1818;
          }
        }
        .checkbox-div2 input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer;
        }

        .checkbox-div2 label {
          position: relative;
          cursor: pointer;
          margin-left: 3em;
          text-align: left;
          font-size: 1.3em;
          font-weight: 500;
          font-family: "Poppins", sans-serif;
          letter-spacing: 0.32px;
          color: #1a1818;
          opacity: 1;
        }

        .checkbox-div2 label:before {
          position: absolute;
          left: -3em;
          top: 0.3em;
          content: "";
          -webkit-appearance: none;
          background-color: white;
          border: 1px solid #707070;
          // box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
          //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 0.8em;
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;
          margin-bottom: 0.45em;
          margin-right: 1em;
        }
        .checkbox-div2 input:checked + label:before {
          background-color: #00933f;
          border: 1px solid #00933f;
        }
        .checkbox-div2 input:checked + label:after {
          content: "";
          display: block;
          position: absolute;
          top: 0.55em;
          left: -2.4em;
          width: 0.5em;
          height: 1em;
          background-color: #00933f;
          border: solid #fff;
          border-width: 0 3px 3px 0;
          transform: rotate(45deg);
        }
      }
    }
    .diwali_offer_image {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 45.438em;
    }
  }
}

@media (min-width: 1950px) {
  .form_sec {
    height: 100vh;
    .form_background {
      .background_img {
        margin: 2em 15.188em 0;
      }
      .form_wrapper {
        padding-bottom: 18em;

        .close_message {
          bottom: 10em;
        }
      }
    }
  }
}
@media (max-width: 1920px) {
  .form_sec .form_background {
    .background_img {
      margin: 2em 5.188em 0;
    }
    .form_wrapper {
      padding-bottom: 18em;
      .close_message {
        bottom: 11em;
      }
    }
  }
}
@media (max-width: 1600px) {
  .form_sec .form_background {
    .form_wrapper {
      padding-bottom: 10em;
      .close_message {
        bottom: 4em;
      }
    }
  }
}
@media (max-width: 1400px) {
  .form_sec .form_background {
    .background_img {
      margin: 2em 3.188em 0;
      background-size: contain;
    }
  }
}
@media (max-width: 1100px) {
  .form_sec .form_background {
    .background_img {
      margin: 2em 3.188em 0;
      background-size: cover;
    }
    .form_wrapper {
      padding-bottom: 10em;
      max-width: 95%;
    }
  }
}
@media (max-width: 992px) {
  .form_sec .form_background {
    .background_img {
      background-image: url("../../../images/Diwali/form-backgorund-tab.png");
      margin: 2em 3.188em 0;
      background-size: cover;
    }
    .form_wrapper {
      .close_message {
        bottom: 3em;
        max-width: 65%;
      }
    }
  }
}

@media (max-width: 600px) {
  .form_sec {
    background-position: right;
    .form_background {
      .background_img {
        background-image: url("../../../images/Diwali/form-background-mob-320.png");
        margin: 2em 1.188em 0;
        background-size: cover;
      }
      .form_wrapper {
        padding: 3em 0.5em 0;
        max-width: 100%;
        .close_message {
          position: relative;
          bottom: 0;
          margin-top: 2em;
          max-width: 100%;
          font-size: 14px;
        }
        .form_heading {
          font-size: 2.4em;
        }
        .form_flex {
          .form_field_customwidth {
            max-width: 100%;
          }
          .field_wtih_tooltip {
            max-width: 90%;
            .tooltip_icon {
              max-width: 2.5em;
              right: -3.5em;
            }
          }
          .coupon_dummy {
            display: none;
          }
          .zipcode_box {
            br {
              display: none;
            }
          }
          .mobile_no_wrapper {
            .country_code_select_wrapper {
              max-width: 15%;
              select {
                background-color: transparent;
                -webkit-appearance: none;
                line-height: 2em;
                padding: 0.5em 1em 0.5em 0.5em;
              }
              .dropDownArrow {
                top: 42%;
                right: 0.7em;
                max-width: 1.1em;
              }
            }
          }
          .dropdown_field {
            position: relative;
            .dropDownArrow {
              bottom: 18%;
              right: 1.2em;
              max-width: 1.5em;
            }
            .dropDownArrow2 {
              bottom: 20%;
              right: 1.2em;
              max-width: 1.5em;
            }
          }
        }
        .checkbox_wrapper {
          max-width: 100%;
          .term_error {
            font-size: 11px;
            margin-left: 40px;
          }
          .checkbox-div2 {
            label {
              font-size: 14px;
              margin-left: 40px;
            }
          }
        }
        .submit_btn {
          padding: 0;
          box-shadow: 4px 4px 4px #00000029;
        }
      }
      .mobile_gift_img {
        width: 100%;
        margin-top: -3em;
      }
    }
  }
  .form_field_wrapper {
    .form_label {
      font-size: 1.4em;
    }
    .form_input {
      line-height: 2em;
      font-size: 1.4em;
      margin: 2px 0;
    }
    select {
      line-height: 2em;
      font-size: 1.4em;
    }
    input[type="radio"] + label {
      font-size: 1.4em;
    }
  }
}
