$color-00C4A7: #00c4a7;
$color-CC121D: #cc121d;
.diwali_bannersec {
  position: relative;
  background-image: url("../../../images/Diwali/Golden-Jublee-banner-bg@2x.jpg");
  // background-size: cover;
  // background-repeat: no-repeat;
  margin-top: 7em;
  // height: 100vh;
  .bannerimg {
    width: 100%;
  }
  .text_container {
    position: absolute;
    left: 12%;
    top: 28%;
    p {
      text-align: center;
      font-size: 1.438em;
      line-height: 1.478em;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .bannerhand_wrapper {
      text-align: center;
      animation: zoom-in-zoom-out 2s ease-out infinite;
      img {
        width: 100%;
        max-width: 13em;
      }
    }

    .participate_btn {
      width: 100%;
      min-width: 22.688em;
      background-color: $color-CC121D;
      text-align: center;
      color: #fff;
      padding-top: 1.125em;
      padding-bottom: 1.125em;
      border-radius: 1.25em;
      font-weight: 500;
      cursor: pointer;
      transition: 0.4s box-shadow;
      .dsf {
        font-size: 1.688em;
        letter-spacing: 0.54px;
      }
      span {
        font-size: 1.313em;
        letter-spacing: 0.42px;
      }
    }
    .participate_btn:hover {
      box-shadow: 0px 7px 25px $color-CC121D;
    }
  }

  .grand-price {
    position: absolute;
    top: 60%;
    left: 18%;
    animation: 2.5s slide-left;
    animation-delay: 500ms;
    animation-fill-mode: forwards;

    visibility: hidden;

    img {
      width: 100%;
      max-width: 25em;
    }
  }

  .chance-to-win {
    position: absolute;
    top: 53.5%;
    left: 30%;
    // animation: 2s slide-left;
    // animation-delay: 250ms;
    // animation-fill-mode: forwards;
    // visibility: hidden;

    img {
      width: 100%;
      max-width: 35em;
    }
  }

  .golden-bonanza {
    position: absolute;
    top: 4.8%;
    left: 23%;
    img {
      width: 100%;
      max-width: 42.7em;
    }
  }

  .enter-coupon {
    position: absolute;
    top: 35%;
    left: 27.3%;
    overflow: hidden;
    img {
      overflow: hidden;
      width: 100%;
      max-width: 83.5%;
      z-index: 0;
      &::before {
        background: linear-gradient(
          to right,
          fade_out(#fff, 1) 0%,
          fade_out(#fff, 0.7) 100%
        );
        content: "";
        display: block;
        height: 75px;
        left: -75%;
        position: absolute;
        top: 0;
        transform: skewX(-25deg);
        width: 50%;
        z-index: 2;
      }

      &:hover,
      &:focus {
        &::before {
          animation: entershine 0.85s;
        }
      }
    }
  }

  .fifty-years {
    position: absolute;
    right: 1.8%;
    top: 0;
    animation: zoom-in-zoom-out 2s ease-out infinite;
    img {
      width: 100%;
      max-width: 12.2em;
    }
  }

  .shilpa {
    position: absolute;
    bottom: 0;
    left: 48%;
    img {
      width: 100%;
      width: 57em;
    }
  }

  .diwali_winner_swipers {
    position: relative;
    bottom: 0;
    // margin-top: 3.2em;
    background-color: #740003;
    display: flex;
    align-items: center;
    z-index: 2;
    .winner_heading {
      font-size: 1.25em;
      color: #f4d66f;
      text-align: center;
      width: 100%;
      max-width: 11em;
      font-family: "DVB-TTBhimaEN";
      br {
        display: none;
      }
    }
    .swiper-button-disabled {
      pointer-events: initial;
      opacity: 1;
    }
    .swiper-container {
      position: static;
      min-height: auto;
    }
    .swiper-slide {
      cursor: auto;
      width: auto;
    }
    .winner_box {
      margin: 0.5em 1em 0.5em 0;
      p {
        font-size: 1.25em;
        color: #fff;
        text-align: center;
        cursor: auto;
        font-family: "DVB-TTBhimaEN";
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 2560px) {
  .diwali_bannersec {
    .grand-price {
      position: absolute;
      top: 59%;
      left: 19%;
      img {
        width: 100%;
      }
    }
    .chance-to-win {
      top: 54%;
      left: 30%;
      img {
        width: 100%;
      }
    }

    .golden-bonanza {
      top: 6%;
      left: 25%;
      img {
        width: 100%;
        max-width: 100%;
      }
    }

    .enter-coupon {
      top: 31.5%;
      left: 27%;
      img {
        width: 100%;
        max-width: 100%;
      }
    }

    .shilpa {
      left: 45%;
      img {
        width: 75em;
      }
    }
  }
}

@media (max-width: 1920px) {
  .diwali_bannersec {
    margin-top: 8em;
    .grand-price {
      position: absolute;
      top: 59%;
      left: 18%;
      img {
        width: 100%;
        max-width: 25em;
      }
    }

    .chance-to-win {
      top: 55%;
      left: 30%;
      img {
        width: 100%;
        max-width: 33em;
      }
    }

    .golden-bonanza {
      top: 4%;
      left: 27%;
      img {
        width: 100%;
        max-width: 40em;
      }
    }

    .enter-coupon {
      top: 32%;
      left: 29%;
      img {
        width: 100%;
        max-width: 91%;
      }
    }

    .shilpa {
      left: 46%;
      img {
        width: 100%;
        max-width: 62em;
      }
    }
  }
}

@media (max-width: 1600px) {
  .diwali_bannersec {
    margin-top: 8.7em;
    .chance-to-win {
      top: 56%;
      left: 31%;
      img {
        width: 100%;
        max-width: 31em;
      }
    }

    .golden-bonanza {
      top: 4%;
      left: 27%;
      img {
        width: 100%;
        max-width: 39em;
      }
    }

    .enter-coupon {
      top: 33%;
      left: 28%;
      img {
        width: 100%;
        max-width: 85%;
      }
    }

    .shilpa {
      left: 48%;
      img {
        width: 54em;
      }
    }
  }
}

@media (max-width: 1536px) {
  .diwali_bannersec {
    margin-top: 6.7em;
    .grand-price {
      position: absolute;
      top: 59%;
      left: 18%;
      img {
        width: 100%;
        max-width: 25em;
      }
    }

    .chance-to-win {
      top: 56%;
      left: 31%;
      img {
        width: 100%;
        max-width: 31em;
      }
    }

    .golden-bonanza {
      top: 4%;
      left: 27%;
      img {
        width: 100%;
        max-width: 39em;
      }
    }

    .enter-coupon {
      top: 33%;
      left: 28%;
      img {
        width: 100%;
        max-width: 85%;
      }
    }

    .shilpa {
      left: 50%;
      img {
        width: 49em;
      }
    }
  }
}

@media (max-width: 1440px) {
  .diwali_bannersec {
    .chance-to-win {
      top: 54%;
      left: 30%;
      img {
        width: 100%;
        max-width: 33em;
      }
    }

    .golden-bonanza {
      top: 4%;
      left: 28%;
      img {
        width: 100%;
        max-width: 38em;
      }
    }

    .enter-coupon {
      top: 33%;
      left: 28%;
      img {
        width: 100%;
        max-width: 80%;
      }
    }

    .shilpa {
      left: 49.5%;
      img {
        width: 51em;
      }
    }
  }
}

@media (max-width: 1366px) {
  .diwali_bannersec {
    .grand-price {
      top: 58.5%;
      left: 18%;
      img {
        width: 100%;
        max-width: 24em;
      }
    }

    .chance-to-win {
      top: 55%;
      left: 31%;
      img {
        width: 100%;
        max-width: 30em;
      }
    }

    .golden-bonanza {
      top: 3%;
      left: 28%;
      img {
        width: 100%;
        max-width: 35em;
      }
    }

    .enter-coupon {
      top: 31.5%;
      left: 28%;
      img {
        width: 100%;
        max-width: 73%;
      }
    }

    .shilpa {
      left: 50%;
      img {
        width: 50em;
      }
    }
  }
}

@media (min-width: 2000px) {
  .diwali_bannersec {
    .fifty-years {
      top: 3.5%;
    }
  }
}
@media (max-width: 1280px) {
  .diwali_bannersec {
    .grand-price {
      top: 58.5%;
      left: 19%;
      img {
        width: 100%;
        max-width: 24em;
      }
    }

    .chance-to-win {
      top: 55%;
      left: 31%;
      img {
        width: 100%;
        max-width: 31em;
      }
    }

    .golden-bonanza {
      top: 3%;
      left: 27.7%;
      img {
        width: 100%;
        max-width: 37em;
      }
    }

    .enter-coupon {
      top: 31.5%;
      left: 27%;
      img {
        width: 100%;
        max-width: 76%;
      }
    }

    .fifty-years {
      right: 1.8%;
      top: 3.5%;
      img {
        width: 100%;
        max-width: 12.2em;
      }
    }

    .shilpa {
      img {
        width: 52em;
      }
    }
  }
}

@media (max-width: 992px) {
  .diwali_bannersec {
    margin-top: 8.8em;
    .text_container {
      position: absolute;
      top: auto;
      left: 12%;
      bottom: 28%;
      .bannerhand_wrapper {
        img {
          max-width: 8em;
        }
      }
    }
    .grand-price {
      top: 65%;
      left: 20%;
      img {
        width: 100%;
        max-width: 14em;
      }
    }

    .chance-to-win {
      top: 60%;
      left: 30.5%;
      img {
        width: 100%;
        max-width: 19em;
      }
    }

    .golden-bonanza {
      top: 3%;
      left: 26%;
      img {
        width: 100%;
        max-width: 27em;
      }
    }

    .enter-coupon {
      top: 31.5%;
      left: 25%;
      img {
        width: 100%;
        max-width: 48%;
      }
    }

    .fifty-years {
      top: 0;
      img {
        width: 100%;
        max-width: 11em;
      }
    }

    .shilpa {
      left: 33em;
      img {
        width: 35em;
      }
    }
  }
}

@media (max-width: 600px) {
  .diwali_bannersec {
    background-image: url("../../../images/Diwali/Background-mobile.jpg");
    background-size: contain;
    margin-top: 7.3em;
    margin-bottom: 0;
    .diwali_winner_swipers {
      height: 5em;
      .winner_heading {
        max-width: 5em;
        font-size: 1.6em;
        br {
          display: block;
        }
      }
      .winner_box {
        p {
          font-size: 1.6em;
          margin-top: 0.8em;
        }
      }
    }

    .text_container {
      position: absolute;
      top: auto;
      left: 11%;
      bottom: 28%;
      .bannerhand_wrapper {
        img {
          max-width: 8em;
        }
      }
    }

    .grand-price {
      top: 43%;
      left: 8%;
      img {
        width: 100%;
        max-width: 17em;
      }
    }

    .chance-to-win {
      top: 41%;
      left: 35%;
      img {
        width: 100%;
        max-width: 21em;
      }
    }

    .golden-bonanza {
      top: 17%;
      left: 10%;
      img {
        width: 100%;
        max-width: 28em;
      }
    }

    .enter-coupon {
      top: 32%;
      left: 15%;
      img {
        width: 100%;
        max-width: 85%;
      }
    }

    .fifty-years {
      top: 0;
      img {
        width: 100%;
        max-width: 9em;
      }
    }

    .shilpa {
      left: 0;
      img {
        width: 35.5em;
      }
    }
  }
}

@media (max-width: 400px) {
  .diwali_bannersec {
    .shilpa {
      left: 1.5%;
      img {
        width: 33em;
      }
    }
  }
}
@media (max-width: 370px) {
  .diwali_bannersec {
    .shilpa {
      left: 5%;

      img {
        width: 30em;
      }
    }
  }
}
@media (max-width: 320px) {
  .diwali_bannersec {
    .grand-price {
      top: 43%;
      left: 9%;
      img {
        width: 100%;
        max-width: 15em;
      }
    }

    .chance-to-win {
      top: 41%;
      left: 35%;
      img {
        width: 100%;
        max-width: 18em;
      }
    }

    .golden-bonanza {
      top: 15%;
      left: 6%;
      img {
        width: 100%;
        max-width: 25.5em;
      }
    }

    .enter-coupon {
      left: 15%;
      img {
        width: 100%;
        max-width: 85%;
      }
    }

    .fifty-years {
      top: 0;
      img {
        width: 100%;
        max-width: 9em;
      }
    }

    .shilpa {
      left: 0;
      img {
        width: 29em;
      }
    }
  }
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes spin {
  0%,
  10% {
    transform: perspective(400px);
  }
  90%,
  100% {
    transform: perspective(400px) rotateY(180deg);
  }
}

@keyframes slide-left {
  from {
    margin-left: -100%;
    visibility: hidden;
  }
  to {
    margin-left: 0%;
    visibility: visible;
  }
}
@keyframes entercoupon {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes entershine {
  100% {
    left: 125%;
  }
}