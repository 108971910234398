@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
// font-family: 'Cinzel', serif;
// font-family: 'Poppins', sans-serif;
$color-00C4A7: #00c4a7;
$color-CC121D: #cc121d;
.loader_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.landing_page_main {
  .swiper-container {
    min-height: auto;
  }
  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .error {
    color: #9c0000;
    font-weight: 500;
    font-size: 1.1em;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
  }
  input:focus,
  select:focus {
    outline: none;
  }
  font-family: "Poppins", sans-serif;
  img {
    object-fit: cover;
  }
  p,
  figure {
    margin: 0;
    padding: 0;
  }
  .sec_heading {
    font-size: 3.125em;
    font-family: "Cinzel", serif;
    color: #1a1818;
    letter-spacing: 1px;
    font-weight: 400;
  }
  .para_desc {
    font-size: 1.35em;
    color: #1a1818;
    letter-spacing: 0.4px;
    line-height: 1.9em;
    font-weight: 400;
    .note_span {
      font-weight: bold;
    }
    .here_link {
      font-weight: bold;
      cursor: pointer;
      color: $color-CC121D;
      text-decoration: underline;
    }
  }
  .curved_design_logo {
    width: 100%;
    max-width: 3.625em;
  }
}

.header {
  height: 9em;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-left: 9.5em;
  padding-right: 9.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  .terms_anchor {
    color: #1a1818;
    text-decoration: none;
  }
  .hamburger_icon {
    display: none;
  }
  .laxmilogo {
    max-width: 8.438em;
  }
  .nav_flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .nav_links {
    margin-bottom: 0;
    padding-left: 0;
    li {
      display: inline-block;
      font-size: 1.375em;
      letter-spacing: 0.44px;
      margin-right: 2.636em;
      cursor: pointer;
      &:hover {
        font-weight: 500;
      }
      a {
        color: #1a1818 !important;
        text-decoration: none;
      }
      span {
        color: #1a1818 !important;
      }
    }
  }
  .social_icons {
    a {
      margin-right: 1.438em;
    }
    img {
      max-width: 3.125em;
      transition: 0.4s transform;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

.bannersec {
  position: relative;
  // background-image: url("../../images/landing-page/aboutbg.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  margin-top: 7em;
  .bannerimg {
    width: 100%;
    // height: 100vh;
  }
  .text_container {
    position: absolute;
    left: 30%;
    bottom: 40%;
    p {
      text-align: center;
      font-size: 1.438em;
      line-height: 1.478em;
      letter-spacing: 0.46px;
      text-transform: uppercase;
      font-weight: 500;
    }
    .bannerhand_wrapper {
      text-align: center;
      img {
        width: 100%;
        max-width: 2.25em;
      }
    }
    .participate_btn {
      width: 100%;
      min-width: 22.688em;
      background-color: $color-CC121D;
      text-align: center;
      color: #fff;
      padding-top: 1.125em;
      padding-bottom: 1.125em;
      border-radius: 1.25em;
      font-weight: 500;
      cursor: pointer;
      transition: 0.4s box-shadow;
      .dsf {
        font-size: 1.688em;
        letter-spacing: 0.54px;
      }
      span {
        font-size: 1.313em;
        letter-spacing: 0.42px;
      }
    }
    .participate_btn:hover {
      box-shadow: 0px 7px 25px $color-CC121D;
    }
  }
}
.aboutsec {
  background-image: url("../../images/landing-page/aboutbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 9.438em;
  padding-top: 19.688em;
  margin-top: -12em;
  .about_flex {
    display: flex;
    justify-content: flex-start;
    border-radius: 5.313em;
    box-shadow: 0px 3px 99px #00000029;
  }
  .about_left,
  .about_right {
    width: 100%;
    max-width: 50%;
  }
  //   .aboutleftimg {
  //     width: 100%;
  //   }
  .about_right {
    background-color: #fff;
    padding-top: 8em;
    padding-bottom: 8em;
    text-align: center;
    border-top-right-radius: 4.688em;
    border-bottom-right-radius: 4.688em;
    .para_desc {
      width: 100%;
      max-width: 78%;
      text-align: left;
      margin: 0 auto;
      margin-top: 1.15em;
    }
    .about_para {
      height: 16em;
      padding-right: 0.5em;
      overflow: auto;
      .rules_div {
        font-weight: 600;
      }
      &::-webkit-scrollbar {
        width: 0.5em;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        background-color: #aba6a6;
      }
    }
  }
  .about_left {
    background-color: #00c4a7;
    position: relative;
    border-top-left-radius: 4.688em;
    border-bottom-left-radius: 4.688em;
  }
  .shilpaimg {
    position: absolute;
    max-width: 50.5em;
    width: 100%;
    bottom: 0;
    height: auto;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .aboutplayicon {
    position: absolute;
    width: 100%;
    max-width: 11.563em;
    bottom: 35%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
  .watch_strip {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 1.5em;
    letter-spacing: 0.4px;
    background-color: #e7c16d;
    color: #ffffff;
    padding-top: 1.05em;
    padding-bottom: 1.05em;
    width: 100%;
    text-align: center;
    border-bottom-left-radius: 3em;
  }
}
.partsec {
  padding-top: 8.625em;
  padding-bottom: 7.063em;
  position: relative;
  .partdesignimg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .text_container {
    text-align: center;
    margin-bottom: 3.75em;
  }
  .part_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .part_box {
    width: 100%;
    max-width: 29.5%;
    text-align: center;
    box-shadow: 0px 0px 45px #0000004a;
    border-radius: 2.5em;
    min-height: 35.313em;
    padding-bottom: 4.125em;
    .hashtag_heading {
      font-size: 1.875em;
      font-weight: 500;
      color: #ffffff;
      background-color: $color-00C4A7;
      border-radius: 1.333em;
      letter-spacing: 0.6px;
      padding-top: 1.3em;
      padding-bottom: 1.3em;
    }
    .hashtag_info_wrapper {
      margin-top: 2.625em;
    }
    .hashtag_para {
      width: 100%;
      max-width: 85%;
      margin: 0 auto;
      height: 15em;
      overflow-y: auto;
      padding-right: 0.5em;
      &::-webkit-scrollbar {
        width: 0.5em;
        cursor: pointer;
      }
      &::-webkit-scrollbar-track {
        background-color: #aba6a6;
      }
      span {
        font-weight: bold;
      }
    }
    .hashtag_btn {
      border: none;
      min-width: 16.625em;
      height: 4.188em;
      background-color: $color-CC121D;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 1.375em;
      border-radius: 0.75em;
      padding-left: 1em;
      padding-right: 1em;
      transition: 0.4s box-shadow;
      img {
        max-width: 1.688em;
        padding-right: 0.9em;
        box-sizing: content-box;
      }
      span {
        font-size: 1.25em;
        letter-spacing: 0.4px;
        font-weight: bold;
        text-transform: uppercase;
        color: #fff;
      }
      &:hover {
        box-shadow: 0px 7px 25px $color-CC121D;
      }
    }
  }
}
.form_testi_gallery_sec {
  background-color: #fff;
  background-image: url("../../images/landing-page/formbg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.formsec {
  padding-top: 21.25em;
  // padding-bottom: 20.625em;
  padding-bottom: 15em;
  .text_container {
    text-align: center;
    margin-bottom: 2.625em;
  }
  .form_flex {
    display: flex;
    justify-content: center;
    box-shadow: 0px 3px 99px #00000029;
    border-radius: 2.5em;
  }
  .form_left {
    width: 100%;
    max-width: 63%;
    background-color: #fff;
    border-top-left-radius: 2.5em;
    border-bottom-left-radius: 2.5em;
    padding-top: 6em;
    padding-bottom: 2.5em;
    padding-left: 3em;
    padding-right: 3.938em;
  }
  .form_right {
    width: 100%;
    max-width: 37%;
  }
  .formrightimg {
    width: 100%;
    height: 100%;
    border-top-right-radius: 2.5em;
    border-bottom-right-radius: 2.5em;
  }
  .participate_form_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .field_box {
    width: 100%;
    max-width: 47%;
    position: relative;
    margin-bottom: 3.7em;
  }
  .input_field,
  .input_field_dropdown {
    width: 100%;
    border: 0;
    border-bottom: 1.3px solid rgba(112, 112, 112, 0.5);
    font-size: 1.5em;
    line-height: 1.167em;
    letter-spacing: 0.36px;
    background-color: transparent;
  }
  .input_field_dropdown {
    cursor: pointer;
    padding-left: 0.2em;
  }
  .floting_label {
    font-size: 1em;
    line-height: 1.236em;
    letter-spacing: 0.18px;
    color: #1a1818;
    position: absolute;
    left: 0em;
    top: 0em;
    pointer-events: none;
    transition: 0.2s ease all;
    text-transform: capitalize;
    font-weight: 400;
    span {
      color: $color-CC121D;
    }
  }

  .input_field:focus ~ .floting_label,
  .input_field:not(:placeholder-shown) ~ .floting_label {
    top: -1.4em;
    left: 0.2em;
    font-size: 0.875em;
    letter-spacing: 0.28px;
  }

  .input_field_dropdown:focus ~ .floting_label,
  .input_field_dropdown:not([checkval=""]):valid ~ .floting_label {
    top: -1.4em;
    left: 0;
    font-size: 0.875em;
    letter-spacing: 0.28px;
  }

  .input_field_textarea {
    outline: 0;
    resize: none;
    height: 4em;
  }
  .mobile_field_box_flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .mobile_field_box {
    position: relative;
    width: 100%;
    max-width: 81%;
  }
  .country_code_select_wrapper {
    width: 100%;
    max-width: 13%;
    position: relative;
    cursor: pointer;
    transform: translateY(0.1em);
    select {
      width: 100%;
      cursor: pointer;
      font-weight: 300;
    }
    select:focus {
      border-bottom-color: #707070;
    }
    .dropDownArrow {
      position: absolute;
      pointer-events: none;
      top: 0.5em;
      right: 0;
      width: 100%;
      max-width: 1em;
    }
  }
  .dropDownArrow {
    position: absolute;
    pointer-events: none;
    top: 0.5em;
    right: 0;
    width: 100%;
    max-width: 1em;
  }
  .upload_file_wrapper {
    width: 100%;
    max-width: 47%;
    .upload_heading {
      font-size: 1.25em;
      letter-spacing: 0.24px;
    }
    .upload_label {
      display: flex;
      width: 100%;
      border: 1px dotted #707070;
      height: 4.688em;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-top: 0.7em;
      .uploadicon {
        width: 100%;
        max-width: 1.188em;
      }
      span {
        font-size: 1.125em;
        color: #1a1818;
        font-weight: bold;
        padding-left: 0.5em;
        text-decoration: underline;
      }
    }
    .upload_info_flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .upload_info {
        width: 100%;
        font-size: 1.125em;
        &:nth-child(1) {
          max-width: 67%;
        }
        &:nth-child(2) {
          text-align: right;
          max-width: 44%;
        }
      }
    }
    .file_name {
      font-size: 1.125em;
    }
  }
  .checkbox_flex {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1.75em;
  }
  .checkbox_content {
    font-size: 1.2em;
    line-height: 1.875em;
    letter-spacing: 0.32px;
    transform: translateY(-0.2em);
    .terms_link {
      color: $color-CC121D;
      text-decoration: underline;
    }
  }
  .label_text {
    position: relative;
    cursor: pointer;
  }
  .label_text:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid #707070;
    width: 1.45em;
    height: 1.3em;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 0.9em;
  }

  .check_box {
    display: none;
  }
  .check_box:checked + .label_text {
    font-weight: bold;
    color: #ed1c24;
  }
  .check_box:checked + .label_text:before {
    background-color: #ed1c24;
    border: 1px solid #ed1c24;
  }
  .check_box:checked + .label_text:after {
    content: "";
    display: block;
    position: absolute;
    top: 0.55em;
    left: 0.273em;
    width: 0.4em;
    height: 0.8em;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translateY(-50%);
  }

  .participate_submit_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0px 3px 16px #cc121d;
    border-radius: 0.75em;
    background-color: #cc121d;
    color: #ffffff;
    border: 0;
    width: 100%;
    max-width: 47%;
    height: 4.063em;
    margin-top: 2.938em;
    transition: 0.4s box-shadow;
    &:hover {
      box-shadow: 0px 7px 25px $color-CC121D;
    }
    span {
      font-size: 1.25em;
      letter-spacing: 0.6px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}
.testimonialsec {
  padding-bottom: 11em;
  .text_container {
    text-align: center;
    margin-bottom: 4.875em;
  }
  .testimonial_swiper_wrapper {
    padding-left: 16.375em;
    padding-right: 16.375em;
    position: relative;
    .testi_arrow {
      max-width: 6.25em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      cursor: pointer;
    }
    .left_arrow {
      left: 4%;
    }
    .right_arrow {
      right: 4%;
    }
    .testimonial_swiper {
      border-radius: 1.875em;
      min-height: auto;
    }
    .testimonial_box {
      text-align: center;
      padding: 3.625em 3em;
      background-color: #fff;
      border-radius: 1.875em;
      box-shadow: 0px 3px 25px #00000029;
      position: relative;
    }
    .testimonial_img {
      border-radius: 2em;
      width: 100%;
      box-shadow: 0px 3px 6px #00000029;
    }
    .testimonial_img_wrapper {
      padding: 1em;
      border: 1px solid #707070;
      border-radius: 1.9em;
    }
    .para_desc {
      margin-top: 0.9em;
    }
    .whiteplayicon {
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 3.375em;
    }
    .testidesign {
      max-width: 35em;
      position: absolute;
    }
    .testidesign1 {
      left: 9em;
      top: -8em;
    }
    .testidesign2 {
      right: 9em;
      bottom: -9em;
    }
  }
}
.gallerysec {
  padding-bottom: 11.625em;
  .text_container {
    text-align: center;
    margin-bottom: 4.875em;
  }
  .gallery_images_wrapper {
    padding-left: 17.375em;
    padding-right: 17.375em;
    .gallery_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2.188em;
    }
    .galleryimg_wrapper {
      position: relative;
      &:nth-child(1) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      .galleryimg {
        width: 100%;
      }
      .galleryplayicon {
        position: absolute;
        max-width: 10.625em;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
      }
    }
  }
}
.winnersec {
  background-color: $color-00C4A7;
  padding-top: 5.875em;
  padding-bottom: 9.375em;
  position: relative;
  .winnerbgimg {
    position: absolute;
    top: 4em;
    left: 0;
    // z-index: -1;
    width: 100%;
  }
  .text_container {
    text-align: center;
    .sec_heading {
      color: #fff;
      font-weight: 400;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    position: relative;
    z-index: 2;
  }
  .starimg {
    max-width: 12.563em;
  }
  .winner_btn {
    font-size: 1.25em;
    min-width: 11.9em;
    height: 3.3em;
    color: #838383;
    border-radius: 0.5em;
    border: 0;
    text-transform: uppercase;
    transition: 0.3s color;
    background-color: #fff;
    position: relative;
    z-index: 2;
    cursor: auto;
    &:nth-child(2) {
      margin-left: 1.5em;
    }
    &.active {
      color: #ce0024;
      font-weight: 600;
    }
  }
  .winner_swipers {
    padding-left: 17.438em;
    padding-right: 17.438em;
    // margin-top: 3.2em;
    margin-top: 6.2em;
    position: relative;
    z-index: 2;
    .swiper-button-disabled {
      pointer-events: initial;
      opacity: 1;
    }
    .swiper-container {
      position: static;
      min-height: auto;
      // display: none;
      // height: 0;
      // transition: transform 0.6s ease-out; // note that we're transitioning transform, not height!
      // transform-origin: top;
      // transform: scaleY(0); // implicit, but good to specify explicitly
    }
    // .swiper-container.active {
    // height: auto;
    // transform: scaleY(1);
    // display: block;
    // }
    .winnerimg {
      width: 100%;
      border-radius: 1.875em;
      box-shadow: 0px 3px 24px #ffffff6e;
    }
    .swiper-slide {
      cursor: auto;
    }
    .winner_box {
      p {
        font-size: 2em;
        color: #fff;
        text-align: center;
        margin-top: 0.5em;
        font-weight: 600;
        letter-spacing: 0.48px;
        cursor: auto;
      }
    }
    .winner_arrow {
      max-width: 6.25em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      cursor: pointer;
    }
    .left_arrow {
      left: 4%;
    }
    .right_arrow {
      right: 4%;
    }
  }
}
.instasec {
  position: relative;
  padding-top: 7.75em;
  padding-bottom: 15em;
  // background-image: url("../../images/landing-page/instabg.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  .instaleftdot {
    position: absolute;
    width: 100%;
    max-width: 27.5em;
    top: 2em;
    left: 6em;
  }
  .instarightdot {
    position: absolute;
    width: 100%;
    max-width: 16em;
    bottom: 9em;
    right: 0;
  }
  .instabg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .text_container {
    text-align: center;
    margin-bottom: 2.5em;
  }
  .insta_swiper_wrapper {
    padding-left: 17.438em;
    padding-right: 17.438em;
    position: relative;
  }
  .swiper-container {
    min-height: auto;
  }
  .instaimg {
    width: 100%;
    border-radius: 1.25em;
    height: 28em;
  }
  .testi_arrow {
    max-width: 6.25em;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
  }
  .left_arrow {
    left: 4%;
  }
  .right_arrow {
    right: 4%;
  }
}

.footer {
  background-image: url("../../images/landing-page/footerbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  // background-position: top, bottom;
  padding-top: 20em;
  padding-bottom: 6.25em;
  text-align: center;
  margin-top: -11em;
  position: relative;
  a {
    text-decoration: none;
    color: #1a1818;
  }
  .social_media_wrapper {
    list-style: none;
    padding-left: 0;
    li {
      display: inline-block;
      &:first-child img {
        margin-left: 0;
      }
    }
    img {
      max-width: 5.125em;
      margin-left: 2.438em;
      transition: 0.4s transform;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .links_wrapper,
  .terms_row {
    font-size: 1.375em;
    letter-spacing: 0.44px;
  }
  .links_wrapper {
    margin-top: 1.818em;
    margin-bottom: 1em;
    span {
      margin-left: 0.5em;
      // margin-right: 0.5em;
      border-right: 1px solid #1a1818;
      padding-right: 0.5em;
      &:last-child {
        border-right: 0;
      }
    }
    a {
      text-transform: capitalize;
      display: inline-block;
    }
  }
  // .terms_row {
  //   span:first-child {
  //     padding-right: 0.5em;
  //     margin-right: 0.5em;
  //     border-right: 1px solid #1a1818;
  //   }
  // }
}

@media (min-width: 2560px) {
  .landing_page_main {
    .my_container {
      max-width: 2076px;
    }
  }
}
@media (max-width: 1919px) {
  .landing_page_main {
    .my_container {
      max-width: 1500px;
    }
  }
  .aboutsec {
    .about_right {
      padding-top: 7em;
      padding-bottom: 7em;
    }
  }
  .formsec {
    .floting_label {
      font-size: 1.2em;
    }
  }
}

@media (max-width: 1600px) {
  .landing_page_main {
    .my_container {
      max-width: 100%;
    }
  }
}
@media (max-width: 1580px) {
  .landing_page_main {
    .my_container {
      max-width: 1300px;
    }
  }
  .testimonialsec {
    .testimonial_swiper_wrapper {
      .testimonial_img {
        height: 21em;
      }
    }
  }
  .winnersec {
    // padding-bottom: 0;
    .winner_swipers {
      .winner_arrow {
        top: 35%;
      }
    }
  }
  .aboutsec {
    .about_left {
      max-width: 45%;
    }
    .about_right {
      max-width: 55%;
    }
  }
}

@media (max-width: 1540px) {
  .landing_page_main {
    font-size: 11px;
  }
}
@media (max-width: 1440px) {
  .landing_page_main {
    font-size: 10.5px;
  }
}
@media (max-width: 1367px) {
  .landing_page_main {
    font-size: 10px;
    .my_container {
      max-width: 1168px;
    }
  }
}
@media (max-width: 1280px) {
  .landing_page_main {
    font-size: 9.5px;
  }
  .testimonialsec {
    .testimonial_swiper_wrapper {
      .testidesign2 {
        bottom: -3em;
      }
    }
  }
}
@media (max-width: 1193px) {
  .formsec {
    .form_left {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  .testimonialsec {
    .testimonial_swiper_wrapper {
      .testidesign {
        max-width: 22em;
      }
    }
  }
  .gallerysec {
    .gallery_images_wrapper {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
  }
}
@media (max-width: 991px) {
  .landing_page_main {
    .my_container {
      max-width: 100%;
    }
  }
  .header {
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0;
  }
  .bannersec {
    margin-top: 9em;
  }
  .aboutsec {
    .about_flex {
      flex-direction: column-reverse;
    }
    .about_left,
    .about_right {
      max-width: 100%;
    }
    .shilpaimg {
      // position: initial;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }
    .about_right {
      border-top-right-radius: 3.2em;
      border-top-left-radius: 3.2em;
      border-bottom-right-radius: 0;
      .para_desc {
        max-width: 90%;
      }
    }
    .about_left {
      border-top-left-radius: 0;
      border-bottom-left-radius: 3.2em;
      border-bottom-right-radius: 3.2em;
      padding-top: 19em;
      padding-bottom: 13em;
      text-align: center;
    }
    .aboutplayicon {
      position: relative;
      left: initial;
      bottom: initial;
      z-index: 1;
    }
    .watch_strip {
      border-bottom-left-radius: 2em;
      border-bottom-right-radius: 2em;
      font-size: 1.4em;
      padding-top: 0.7em;
      padding-bottom: 0.7em;
    }
  }

  .partsec {
    .my_container {
      max-width: 70%;
    }
    .part_flex {
      flex-wrap: wrap;
    }
    .part_box {
      min-height: auto;
      max-width: 100%;
      &:nth-child(n + 2) {
        margin-top: 4.1em;
      }
      .hashtag_para {
        min-height: auto;
        height: auto;
      }
    }
  }
  .formsec {
    .form_flex {
      flex-direction: column;
    }
    .form_left {
      max-width: 100%;
      border-top-left-radius: 2.5em;
      border-top-right-radius: 2.5em;
      border-bottom-left-radius: 0;
      padding-left: 6em;
      padding-right: 6em;
    }
    .form_right {
      max-width: 100%;
    }
    .formrightimg {
      border-top-right-radius: 0;
      border-bottom-right-radius: 2.5em;
      border-bottom-left-radius: 2.5em;
    }
  }
}
@media (max-width: 834px) {
  .landing_page_main {
    .sec_heading {
      font-size: 2.4em;
      letter-spacing: 0.48px;
      max-width: 80%;
      margin: 0 auto;
    }
    .para_desc {
      font-size: 1.4em;
      letter-spacing: 0.28px;
    }
  }
  .bannersec {
    .text_container {
      left: 27%;
      bottom: 28%;
    }
  }
}
@media (max-width: 767px) {
  .video-player {
    transform: translateX(-0.8em) !important;
  }
  // .landing_page_main {
  // }
  .header {
    height: 7.7em;
    .terms_anchor {
      color: #fff;
    }
    .hamburger_icon {
      display: block;
      top: 50%;
      transform: translateY(-50%);
      left: 1.5em;
      position: absolute;
      z-index: 1;
    }
    .laxmilogo_wrapper {
      flex-grow: 1;
      text-align: center;
    }
    .laxmilogo {
      max-width: 6.6em;
    }
    .nav_flex {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: $color-CC121D;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-top-right-radius: 13em;
      padding-left: 1.8em;
      left: -100%;
      transition: 0.3s left;
    }
    .nav_flex.active {
      left: 0;
    }
    .nav_links {
      margin-bottom: 5.6em;
      li {
        display: block;
        margin-right: 0;
        font-size: 1.8em;
        letter-spacing: 0.54px;
        text-transform: uppercase;
        margin-top: 2.333em;
        a {
          color: #fff;
        }
        &:first-child {
          margin-top: 0;
        }
        span {
          color: #fff !important;
        }
      }
    }
    .social_icons {
      img {
        max-width: 5em;
      }
    }
  }

  .bannersec {
    margin-top: 5em;
    .text_container {
      left: 50%;
      bottom: 50%;
      transform: translateX(-50%);
    }
  }
  .aboutsec {
    padding-top: 10.7em;
    padding-bottom: 7.1em;
    margin-top: -5em;
    .about_right {
      padding-top: 4.8em;
      padding-bottom: 7.8em;
      .about_para {
        height: auto;
      }
    }
    .about_left {
      padding-top: 9em;
      padding-bottom: 10em;
    }
  }
  .partsec {
    padding-top: 6.625em;
    padding-bottom: 3.4em;
    .my_container {
      max-width: 100%;
    }
    .part_box {
      .hashtag_btn {
        min-width: 21em;
        height: 6em;
        margin-top: 3.4em;
        span {
          font-size: 1.4em;
        }
      }
      .hashtag_para {
        max-width: 90%;
        font-size: 1.5em;
      }
      .hashtag_info_wrapper {
        margin-top: 3.7em;
      }
    }
  }
  .form_testi_gallery_sec {
    background-image: url("../../images/landing-page/mbformbg.png");
  }
  .formsec {
    padding-top: 11em;
    padding-bottom: 4.8em;
    .label_text {
      font-size: 1.4em;
    }
    .checkbox_content {
      font-size: 1.3em;
    }
    .field_box {
      max-width: 100%;
    }
    .form_left {
      padding-left: 1.2em;
      padding-right: 1.2em;
      padding-top: 4em;
      padding-bottom: 3.4em;
    }
    .upload_file_wrapper {
      max-width: 100%;
      .upload_info_flex .upload_info {
        font-size: 1.3em;
      }
    }
    .participate_submit_btn {
      margin-left: auto;
      margin-right: auto;
      max-width: 70%;
      margin-top: 4em;
      height: 6em;
      span {
        font-size: 1.4em;
      }
    }
    .floting_label {
      font-size: 1.3em;
    }
  }
  .testimonialsec {
    padding-bottom: 4.7em;
    .text_container {
      margin-bottom: 2.5em;
    }
    .testimonial_swiper_wrapper {
      padding-left: 1.5em;
      padding-right: 1.5em;
      .testi_arrow {
        position: initial;
        transform: none;
      }
      .right_arrow {
        margin-left: 3.7em;
      }
      .navigation_wrapper {
        text-align: center;
        margin-top: 2.7em;
        position: relative;
        z-index: 2;
      }
      .testidesign {
        max-width: 14em;
      }
      .testidesign1 {
        left: 0;
        top: -5em;
      }
      .testidesign2 {
        right: 0;
        bottom: 4em;
      }
      .testimonial_box {
        min-height: 37em;
      }
    }
  }
  .gallerysec {
    padding-bottom: 6.8em;
    .text_container {
      margin-bottom: 2.5em;
    }
    .gallery_images_wrapper {
      .gallery_grid {
        grid-template-columns: 100%;
      }
      .galleryimg_wrapper {
        .galleryimg {
          height: 34.1em;
          border-radius: 2em;
        }
      }
      .galleryimg_wrapper:nth-child(1) {
        grid-column-end: 2;
      }
    }
  }
  .winnersec {
    padding-bottom: 4.375em;
    .winnerbgimg {
      top: 0;
    }
    .winner_btn {
      min-width: 90%;
      font-size: 1.8em;
      &:nth-child(2) {
        margin-left: 0;
        margin-top: 1em;
      }
    }
    .winner_swipers {
      padding-left: 1.5em;
      padding-right: 1.5em;
      margin-top: 5.2em;
      .navigation_wrapper {
        text-align: center;
        margin-top: 2.7em;
        position: relative;
        z-index: 2;
      }
      .winner_arrow {
        transform: none;
        position: initial;
      }
      .right_arrow {
        margin-left: 3.7em;
      }
    }
  }
  .instasec {
    padding-top: 4.8em;
    padding-bottom: 14em;
    // background-image: url("../../images/landing-page/mbinstabg.png");
    .instarightdot {
      max-width: 12em;
      bottom: 9em;
    }
    .instaleftdot {
      max-width: 19.5em;
      top: 5em;
      left: 2em;
    }
    .insta_swiper_wrapper {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
    .navigation_wrapper {
      text-align: center;
      margin-top: 2em;
      position: relative;
      z-index: 2;
    }
    .testi_arrow {
      position: initial;
      transform: none;
    }
    .right_arrow {
      margin-left: 2.5em;
    }
  }
  .footer {
    padding-top: 9em;
    background-image: url("../../images/landing-page/mbfooterbg.png");
    .links_wrapper {
      margin-top: 2.818em;
      margin-bottom: 2em;
      span {
        display: block;
        border: 0;
        margin-top: 1em;
      }
    }
    .terms_row {
      span {
        display: block;
        font-size: 14.3px;
        a {
          font-size: 14.3px;
        }
        &:first-child {
          margin-bottom: 0.3em;
          border-right: 0;
        }
      }
    }
  }
}
