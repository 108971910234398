//common form css here
//common form css here
//common form css here
//common form css here
//common form css here
//common form css here
//common form css here
//common form css here

* {
  outline: 0 !important;
}

figure {
  margin: 0;
  padding: 0;
}

.form_field_wrapper {
  width: 100%;
  margin-bottom: 2em;
  .form_label {
    text-align: left;
    font-size: 1.125em;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    letter-spacing: 0.36px;
    opacity: 1;
    margin-bottom: 0.563em;
    .mandatory {
      color: red;
    }
  }
  .form_input {
    -webkit-appearance: none;
    outline: none;
    border: none;
    display: block;
    width: 100%;
    font-size: 1.188em;
    line-height: 1em;
    padding: 0.5em 1em 0.5em 1em;
    background-color: #fff !important;
    border: 0.4000000059604645px solid #707070;
    border-radius: 6px;
    color: #000;
    &::-webkit-input-placeholder {
      /* Edge */
      color: #000;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000;
    }
    &::placeholder {
      color: #000;
    }
  }
  .form_input:-webkit-autofill,
  .form_input:-webkit-autofill:hover,
  .form_input:-webkit-autofill:focus,
  .form_input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s,
      color 5000s ease-in-out 0s;
    color: #000;
  }
  .error_message {
    position: absolute;
    font-size: 1.125em;
    color: red !important;
    &:first-letter {
      text-transform: uppercase;
    }
  }
  select {
    appearance: none;
    background-color: transparent;
    -webkit-appearance: none;
    width: 100%;
    outline: none;
    border: 0.4000000059604645px solid #707070;
    padding: 0.5em 1em 0.5em 1em;
    font-size: 1.188em;
    line-height: 1em;
    border-radius: 6px;
    cursor: pointer;
  }

  select:focus,
  select:hover {
    outline: none;
    background-color: transparent;
    -webkit-appearance: none;
    border: 0.4000000059604645px solid #707070;
  }
  input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
  }

  input[type="radio"] + label {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 1em;
    line-height: 20px;
  }

  input[type="radio"] + label::before {
    content: "";
    display: inline-block;
    width: 20px;
    aspect-ratio: 1;
    border: 1px solid #00933f;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 1;
    transition: all 0.3s;
  }

  input[type="radio"] + label::after {
    content: "";
    display: inline-block;
    width: 10px;
    aspect-ratio: 1;
    border: 1px solid #00933f;
    background: #00933f;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.3s;
  }

  input[type="radio"]:checked + label::after {
    opacity: 1;
  }
}
.swiper-button-disabled {
  opacity: 0.7;
  pointer-events: none;
  transition: 0.5s ease-in-out;
}
//common form css here
//common form css here
//common form css here
//common form css here
//common form css here
//common form css here
//common form css here
@media (min-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: 2426px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

@media (min-width: 1921px) {
  .my_container {
    max-width: 1820px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0;
  }
}
@media (max-width: 600px) {
  .form_field_wrapper {
    .error_message {
      font-size: 12px;
      .strong {
        font-size: 12px !important;
      }
    }
  }
}
