@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.diwali_thankyousec {
  background-image: url("../../../images/Diwali/Golden-Jublee-banner-bg@2x.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  font-family: "Poppins", sans-serif;
  align-items: center;
  justify-content: center;
  padding-top: 9em;
  .thankyou_wrapper {
    height: 80%;
    width: 70%;
    padding: 0.5em 0.7em;
    border: 4px solid #ffd361;
    border-radius: 2.75em;
  }
  .thankyou_content {
    height: 100%;
    border-radius: 2.75em;
    background-color: #b20014;
    padding: 0 7.625em;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .left_arrow_thank {
      margin-right: 1em;
      max-width: 1em;
    }
    .thankyou_btn {
      display: block;
      width: 100%;
      // max-width: 14.438em;
      line-height: 1em;
      font-size: 1.625em;
      padding: 0.5em 1em;
      text-transform: uppercase;
      color: #b20014;
      height: 3em;
      border: none;
      background-color: #fff;
      font-family: "DVB-TTBhimaEN";
      font-weight: 700;
      box-shadow: 13px 17px 7px #00000029;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      z-index: 2;
      &:before {
        content: "";
        display: inline-block;
        transform: translateY(50%);
        left: 0;
        position: absolute;
        border-radius: 50%;
        background-color: #740003;
        z-index: -1;
        transition: transform ease 1s;
      }
      &:before {
        content: "";
        display: inline-block;
        transform: translateY(50%);
        left: -1em;
        position: absolute;
        width: 17em;
        height: 8em;
        border-radius: 50%;
        background-color: #740003;
        z-index: -1;
        transition: transform ease 1s;
      }
      &:hover {
        box-shadow: none;
        transition: all 0.5s ease-in-out;
        &:before {
          transform: translateY(-45%);
        }
        color: white;
        transition: color 0.1s ease;
        transition-delay: 0.2s;
      }
    }
  }
  .thank_heading {
    font-size: 3.75em;
    font-family: "DVB-TTBhimaEN";
    text-transform: uppercase;
    font-weight: 400;
    width: 100%;
    color: #fff;
    margin: 0 auto;
    margin-top: 0.343em;
    text-align: left;
  }
  .thankyoudesc {
    font-size: 1.5em;
    letter-spacing: 0.4px;
    width: 100%;
    max-width: 80%;
    color: #fff;
    text-align: left;
    margin-top: 1em;
    margin-bottom: 2em;
  }
  .thankbtn {
    font-size: 1.25em;
    letter-spacing: 0.4px;
    color: #cc121d;
    text-transform: uppercase;
  }
}
@media (max-width: 767px) {
  .diwali_thankyousec {
    .thankyou_content {
      padding: 0 2.8em;
      .left_arrow_thank {
        max-width: 0.7em;
      }
      .thankyou_btn {
        font-size: 1.4em;
      }
      .thankyou_btn {
        box-shadow: 4px 4px 4px #00000029;
      }
    }
    .thankyou_wrapper {
      width: 80%;
      height: 70%;
    }
    .thank_heading {
      font-size: 2.4em;
      line-height: 1.5em;
    }
    .thankyoudesc {
      max-width: 95%;
      font-size: 1.4em;
    }
  }
}
