.diwali_gallery_sec {
  background-image: url("../../../images/Diwali/Golden-Jublee-banner-bg@2x.jpg");
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  // object-fit: contain;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;
  .gallery_background {
    position: relative;
    padding-top: 9em;
    .backgroud_img {
      background-image: url("../../../images/Diwali/form-backgorund.png");
      // background-size: contain;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 0 5.188em 0;
      filter: drop-shadow(0 0 1.75em black);
    }
    .gallery_swiper_sec {
      padding-bottom: 2.688em;
      .text_container {
        text-align: center;
        margin-top: 4.25em;
        margin-bottom: 1.875em;
        .gallery_heading {
          text-align: center;
          font: normal normal bold 3.125em/64px DVB-TTBhimaEN;
          letter-spacing: 0px;
          color: #fff;
          text-transform: uppercase;
          opacity: 1;
          margin-bottom: 0.5em;
        }
      }
      .gallery_swiper_wrapper {
        position: relative;
        padding: 1em 1.5em;
        margin: 0 -4em;
        .navigation_wrapper {
          margin-top: 2.563em;
          text-align: center;
          .left_arrow,
          .right_arrow {
            cursor: pointer;
            max-width: 6.563em;
            margin: 0 0.8em;
          }
        }
        .diwali_gallery_swiper {
          padding: 1em 3em;
          border-radius: 30px;
          min-height: auto;
          margin: 0;
        }
        .gallery_box {
          cursor: pointer;
          text-align: center;
          padding: 1.375em 1.25em 0.5em;
          background-color: #fff;
          box-shadow: -23px 4px 19px #00000042;
          border-radius: 30px;
          position: relative;
        }
        .gallery_img_wrapper {
          position: relative;
          .diwaliplay {
            position: absolute;
            bottom: -2em;
            left: 50%;
            transform: translate(-50%, 2em);
            max-width: 9em;
          }
        }
        .gallery_img {
          border-radius: 30px;
          width: 100%;
          box-shadow: 0px 3px 6px #00000029;
        }
        .gallery_text {
          font-family: "Poppins", sans-serif;
          font-size: 1.5em;
          color: #ba131a;
          letter-spacing: 0.4px;
          line-height: 1.5em;
          font-weight: 600;
          text-align: center;
          letter-spacing: 0.48px;
          margin-top: 3em;
          .note_span {
            font-weight: bold;
          }
          .here_link {
            font-weight: bold;
            cursor: pointer;
            color: #cc121d;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
.fslightbox-container {
  margin-top: 9em;
}
.fslightbox-full-dimension {
  height: 100vh;
}
@media (min-width: 2000px) {
  .gallery_swiper_sec {
    max-width: 1900px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}
@media (max-width: 1580px) {
  .gallery_swiper_sec {
    .gallery_swiper_wrapper {
      .gallery_img {
        height: 21em;
      }
    }
  }
}
@media (max-width: 767px) {
  .diwali_gallery_sec {
    .gallery_background {
      padding-top: 7em;
      .gallery_swiper_sec {
        padding-bottom: 4.7em;
        .text_container {
          margin-bottom: 1.5em;
          .gallery_heading {
            font-size: 2.4em;
            line-height: 1.5em;
            margin-bottom: 0;
          }
        }
        .gallery_swiper_wrapper {
          padding-left: 1.5em;
          padding-right: 1.5em;
          margin: 0;
          .diwali_gallery_swiper {
            padding-left: 1.5em;
            padding-right: 1.5em;
          }
          .left_arrow,
          .right_arrow {
            position: initial;
            transform: none;
            max-width: 5.4em;
          }
          .right_arrow {
            margin-left: 1.8em;
          }
          .navigation_wrapper {
            text-align: center;
            margin-top: 2.5em;
            position: relative;
            z-index: 2;
          }
          .gallery_box {
            box-shadow: -10px 4px 11px #00000042;
          }
          .gallery_text {
            margin-top: 4em;
            font-size: 1.2em;
          }
        }
      }
    }
  }
  .fslightbox-full-dimension {
    height: 80vh !important;
    -webkit-appearance: none;
  }
}
