//custom styles for html body & so on.. default layout
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
.year_contest_link_container {
  display: none;
}
::-webkit-scrollbar {
  width: 0.5em;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #ce0024;
  border-radius: 9999px;
  cursor: pointer;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.contest_link_wrapper {
  display: none;
}
@font-face {
  font-family: "DVB-TTBhimaEN";
  src: url("./fonts/Dvbbh3bt.ttf");
}

@font-face {
  font-family: "Helvetica 75 Bold";
  src: url("./fonts/HLB.ttf");
}

@font-face {
  font-family: "Helvetica 55 Roman";
  src: url("./fonts/HLR.ttf");
}

// .title-DVB-57 {
//   // font-family: "DVB-TTBhimaEN" !important;
//   font-size: 57px !important;
//   letter-spacing: 0px;
//   color: #1a1818;
//   text-transform: uppercase;
//   opacity: 1;
// }

// .f-HB {
//   // font-family: "Helvetica 75 Bold" !important;
//   font-size: 20px !important;
//   font-weight: bold !important;
//   letter-spacing: 0.6px;
//   letter-spacing: 0.48px;
//   color: #1a1818;
//   opacity: 1;
// }

// .f-HR-20 {
//   // font-family: "Helvetica 55 Roman" !important;
//   font-weight: normal !important;
//   font-size: 20px !important;
//   letter-spacing: 0.6px !important;
//   color: #000000 !important;
//   text-transform: uppercase;
// }

// .f-HR-22 {
//   // font-family: "Helvetica 55 Roman" !important;
//   font-size: 22px !important;
//   font-weight: normal;
//   letter-spacing: 0.44px;
//   color: #1a1818;
// }

// .f-HR-24 {
//   // font-family: "Helvetica 55 Roman" !important;
//   font-size: 24px;
// }

// .f-DVB-B-70 {
//   font-size: 70px;
//   font-weight: bold;
//   // font-family: "DVB-TTBhimaEN" !important;
//   letter-spacing: 2.1px;
//   color: #ffffff;
//   text-transform: uppercase;
//   opacity: 1;
// }

//spinner
.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

//default colors
.white {
  color: white;
}

//video player style
.video-player {
  height: 40.25em !important;
  width: 74.063em !important;

  @media screen and (max-width: 992px) {
    // width: 640px !important;
    // height: 360px !important;
  }

  @media screen and (max-width: 768px) {
    width: 640px !important;
    height: 360px !important;
  }

  @media screen and (max-width: 576px) {
    width: 440px !important;
    height: 260px !important;
  }

  @media screen and (max-width: 440px) {
    width: 320px !important;
    height: auto !important;
  }
}

//Default Navbar with fixed position
.MainNavBar {
  background: white;
  position: fixed;
  top: 0;
  z-index: 9;
  box-shadow: 0px 3px 6px #00000029;

  .NavbarItems {
    display: flex;
    background: transparent;
    justify-content: space-between;
    // margin: 0 3rem;
    align-items: center;
    // height: 140.75px;
    padding: 1.5em 0; //original
    // padding: 1em 0;

    @media screen and (max-width: 834px) {
      position: relative;
      // height: 90px;
    }

    .navbar-logo {
      flex: 0;

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: 834px) {
        display: flex;
        flex: 1;
        justify-content: center;
      }

      .navbar-brand-logo {
        // height: 115px;
        max-width: 8.125em; //original
        // max-width: 7.65em;

        @media screen and (max-width: 834px) {
          // height: 70px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .menu-icon {
      height: 100%;
      cursor: pointer;
      display: none;
      z-index: 10;

      @media screen and (max-width: 834px) {
        cursor: pointer;
        display: flex;
        align-items: center;
        position: absolute;
      }
      s &:hover {
        cursor: pointer;
      }
    }

    .nav-menu {
      position: relative;
      display: grid;
      grid-template-columns: repeat(8, auto);
      grid-gap: 0;
      list-style: none;
      text-align: center;
      justify-content: end;
      flex: 1;
      align-items: center;
      align-items: center;
      padding: 0;
      margin: 0;

      .nav-links {
        font-size: 1.125em;
        color: #1a1818;
        font-family: "Arimo", sans-serif;
        letter-spacing: 0.6px;
        padding: 0;
        margin: 0 1em;

        &:last-child {
          margin-right: 0;
        }

        @media screen and (max-width: 834px) {
          color: white !important;
          width: 100%;
          padding: 0 1.154em;
          margin: 0;
          margin-bottom: 2.692em;
          font-size: 1.3em;
        }

        &:hover {
          color: #f1022c;
          // filter: brightness(1.5);
          cursor: pointer;
        }
      }
      .nav-links {
        position: relative;
      }
      .nav-links::before {
        position: absolute;
        content: "";
        bottom: -5px;
        width: 5px;
        height: 5px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 50%;
      }
      .nav-links:hover:before {
        background: #f1022c;
      }
      // .nav-links.active {
      //   position: relative;
      //   color: #f1022c;
      // }
      // .nav-links.active::before {
      //   position: absolute;
      //   content: "";
      //   bottom: -5px;
      //   width: 5px;
      //   height: 5px;
      //   left: 0;
      //   right: 0;
      //   margin: auto;
      //   background: #f1022c;
      //   border-radius: 50%;
      // }
      // .nav-links.active:hover:before {
      //   background: #f1022c;
      // }
      @media screen and (max-width: 834px) {
        display: flex;
        flex-direction: column;
        position: absolute;
        height: 575px;
        top: 0;
        left: -100%;
        opacity: 0;
        transition: all 0.5s ease;
        z-index: 1;
        width: 370px;
        padding-inline-start: 0;
        transition: all 0.5s ease-in;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 120px;
        border-top-right-radius: 120px;
        text-align: left;
      }
      @media screen and (max-width: 412px) {
        height: 702px;
      }
      @media screen and (max-width: 375px) {
        height: 702px;
      }
    }
    @media (max-width: 834px) {
      .nav-menu.active {
        display: flex;
        background: #ce0024;
        position: absolute;
        top: -8px;
        left: -15px;
        z-index: 1;
        width: 360px;
        opacity: 1;
        padding-inline-start: 0;
        transition: all 0.5s ease;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 120px;
        border-top-right-radius: 120px;
        text-align: left;
      }
      @media screen and (max-width: 412px) {
        .nav-menu.active {
          width: 360px;
        }
      }
      @media screen and (max-width: 375px) {
        .nav-menu.active {
          width: 360px;
        }
      }
    }
  }

  @media (max-width: 834px) {
    .my_container {
      max-width: 100%;
    }
    .contest_link_wrapper {
      display: block;
    }
    .nav-menu {
      .contest_link {
        animation-name: floating;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        .newtag_img {
          position: absolute;
          top: -7px;
          right: -65px;
        }
        .new_text {
          position: absolute;
          top: -3px;
          right: -55px;
          font-weight: bold;
          font-size: 13px;
          color: #ce0024;
        }
      }
    }
  }
}
@keyframes floating {
  from {
    transform: translate(0, -20px);
  }
  65% {
    transform: translate(10px, -20px);
  }
  to {
    transform: translate(0, -20px);
  }
}
//Footer bottom
.MainFooter {
  position: relative;
  // top: 140.56px;
  // margin-top: 7.5em;
  font-size: 1.325em;
  font-family: "Arimo", sans-serif;

  @media screen and (max-width: 834px) {
    // top: 90px;
    // margin-top: 5em;
    // margin-bottom: 4.769em;
    font-size: 1.3em;
  }
}

// common link styles
a {
  color: white;
  text-decoration: none;
  cursor: pointer;

  :hover {
    cursor: pointer;
    color: white;
  }

  @media (max-width: 834px) {
    font-size: 1.1em;
  }
}

//common banner styles
.banner-container {
  position: relative;
  text-align: center;
  color: white;

  .banner-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  /* title text */
  .banner-title {
    position: absolute;
    top: 50%;
    text-align: left;
    left: 5%;
    transform: translateY(-50%);
    font-size: 4.375em;
    font-family: DVBTTBhimaENBold;
    letter-spacing: 2.1px;

    @media (max-width: 834px) {
      position: absolute;
      top: 50%;
      top: 15%;
      text-align: left;
      left: 5%;
      transform: translate(-50%, -50%);
      font-size: 2.3em;
      font-family: DVBTTBhimaENBold;
      letter-spacing: 2.1px;
      left: 50%;
      width: 100%;
      text-align: center;
    }
  }
  .our-story-title {
    left: 8%;
    @media (max-width: 834px) {
      font-family: "DVBTTBhimaENBold";
      font-size: 4.375em;
      font-weight: bold;
      letter-spacing: 2.1px;
      line-height: 1.043em;
      color: #ffffff;
      position: absolute;
      top: 46%;
      left: 25%;
    }
    @media (max-width: 767px) {
      top: 13%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 2.3em;
      font-family: DVBTTBhimaENBold;
      letter-spacing: 2.1px;
    }
  }
}

//common Main Pages postion relative because navbar is fixed
.MainContent {
  position: relative;
  margin-top: 10.188em;

  @media screen and (max-width: 834px) {
    margin-top: 10.3em;
  }
}
//modal styles
.modal-content {
  background: transparent !important;
}

.modal-dialog {
  max-width: 100% !important;
}
.modal-backdrop {
  background-color: #1a1818bf !important;
}

//modal styles
// .modal-content {
//   background: transparent !important;
// }

// .modal-dialog {
//   max-width: 100% !important;
// }
//kitchen 3 cards css
.video-card {
  height: 31.25em;
  max-width: 26.25em !important;

  @media screen and (max-width: 768px) {
    // min-height: 400px;
    // max-width: 320px;
  }

  .video-thumbnail {
    border-radius: 20px !important;
    height: 100%;
    width: 100%;
  }

  .video-card-img-overlay {
    height: 40%;
    top: auto;
    bottom: 0;
    background: #62d398;
    opacity: 0.9;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin: 0 0.75em;

    @media (max-width: 834px) {
      margin: 0 1.2em;
    }

    @media (max-width: 767px) {
      height: 45%;
    }

    .ytCard {
      position: relative;
      height: 100%;
      padding: 1em 0;

      @media (max-width: 834px) {
        padding: 0;
      }

      .date-text {
        background: #14693d 0% 0% no-repeat padding-box;
        border-radius: 26px;
        opacity: 1;
        height: auto;
        display: inline-block;
        align-items: center;
        font-size: 1.5em;
        padding: 0.333em 0;
        max-width: 10em;
        width: 100%;
        margin: 0 auto;
        margin-top: 0.35em;
        @media (max-width: 767px) {
          font-size: 1.4em;
        }
      }
    }

    .card-title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      // height: 42px;
      text-transform: uppercase;
      font-size: 1.625em;
      font-size: 1.5em;
      padding: 1em 0;
      margin: 0;

      @media (max-width: 767px) {
        font-size: 1.8em;
      }
    }
  }
}

.play-btn {
  height: auto;
  max-width: 5.25em;
  width: 100%;
  transform: translate(-50%, -85%);
  position: absolute;

  @media (max-width: 768px) {
    height: 62px;
    width: 62px;
  }

  &:hover {
    cursor: pointer;
  }
}

.mainCard {
  border-radius: 0px 184px 0px 70px;
  box-shadow: 0px 0px 16px #0000001a;
  border-top-right-radius: 50% !important;

  @media (max-width: 576px) {
    justify-content: center;
    height: 100%;
    width: 250px;
  }

  .card-image {
    background-size: contain;

    @media (max-width: 576px) {
      height: 100%;
      width: 100%;
    }
  }
}

.viewProductsBtn {
  width: 335px;
  height: 65px;
  text-align: center;
  font: normal normal normal 20px/24px Helvetica 75 Bold;
  letter-spacing: 0.6px;
  color: #ffffff;
  opacity: 1;
  background: #ce0024;
  box-shadow: 0px 3px 16px #ce002466;
  border-radius: 33px;
  border: none;
  opacity: 1;

  &:hover {
    cursor: pointer;
  }
}

.lemon-cut {
  position: absolute;
  left: 0;

  @media (max-width: 768px) {
    display: none;
  }
}

.spices-cut {
  position: absolute;
  right: 0;
  transform: translateY(-150px);

  @media (max-width: 768px) {
    display: none;
  }
}

.leaf-cut {
  position: absolute;
  right: 0;

  @media (max-width: 768px) {
    display: none;
  }
}

.modal-header {
  border-bottom: none !important;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0.3rem;
  outline: 0;
  margin: auto;
  justify-content: center;
}

.modal-body {
  margin: auto;
}

.closeModal {
  max-width: 3.969em;
  // height: 63.5px;

  &:hover {
    cursor: pointer;
  }
}

//products category 3 card css
.card-title {
  padding: 16px 0;
}

#categoryCards {
  box-shadow: 0px 0px 16px #0000001a;
  border-bottom-left-radius: 70px;
  border-top-right-radius: 184px;

  @media screen and (max-width: 576px) {
    justify-content: center;
    height: 100%;
    width: 80%;
  }

  .card-image {
    background-size: contain;

    @media screen and (max-width: 576px) {
      height: 100%;
      width: 100%;
    }
  }

  .card-body {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    flex-wrap: nowrap !important;
    flex-direction: column !important;
    flex: 1;
    height: 100%;
  }
}

.viewRecipesBtn {
  // width: 335px;
  // height: 65px;
  text-align: center;
  font: normal normal normal 20px/24px Helvetica 75 Bold;
  letter-spacing: 0.6px;
  color: #ffffff;
  opacity: 1;
  background: #ce0024 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #ce002466;
  border-radius: 33px;
  border: none;
  opacity: 1;
  font-size: 1.25em;
  font-family: HelveticaNeueBold;
  letter-spacing: 0.6px;
  padding: 1em 5em;
  transition: 0.3s ease;
  @media screen and (max-width: 576px) {
    width: 235px;
  }

  &:hover {
    cursor: pointer;
    transition: 0.3s ease;
    background-color: #ff003f;
    // filter: brightness(1.75);
  }
}

//Blogs css
.blogCardLink {
  &:hover {
    cursor: pointer;
    color: black;
  }
}

.swiper-latest-blog {
  max-width: 700px;
}

.my-swiper {
  height: 100%;
  width: 100%;
}
.swiper-container {
  width: 100%;
  height: 100%;
  min-height: 400px;
  justify-content: center;
}

// .swiper-slide {
// font-size: 18px;
// background: #fff;
/* Center slide text vertically */
// min-width: 350px;
// display: flex;
// justify-content: center;
// align-items: center;

// &:hover {
//   cursor: pointer;
// }
// }

.swiper-pagination-bullet {
  background: #ffffff !important;
  border: 1px solid #ce0024 !important;
  box-shadow: 0px 3px 16px #ce002466 !important;
  opacity: 1;
  width: 8px;
  transition: 0.5s ease;
}

.swiper-pagination-bullet-active {
  width: 20px !important;
  border-radius: 12px !important;
  background: #ce0024 !important;
  box-shadow: 0px 3px 16px #ce002466;
  transition: 0.5s ease;
}

.swiper-mobile-visible {
  display: none;
  @media screen and (max-width: 768px) and (min-width: 375px) {
    display: block;
  }
}

//blogs

.verticalCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 50px auto;
  // border: 1px solid #0000001A !important;
  box-shadow: 0px 0px 16px #0000001a !important;
  @media (max-width: 834px) {
    width: 350px;
  }

  @media (max-width: 992px) {
    width: 350px;
  }

  @media (max-width: 375px) {
    width: 100%;
  }

  &.blog-card {
    box-shadow: none;
    border-radius: 20px;
  }

  .mobileBlogImage {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .card-header {
    background: transparent;
  }
}
.blog-card {
  box-shadow: 0px 0px 16px #0000001a;
  border-radius: 20px !important;

  &:hover {
    cursor: pointer;
  }
}

.dualLeftCard {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;

  .dualCard-image {
    @media screen and (min-width: 992px) {
      height: 100%;
      // width: auto;
    }
  }
}

.dualRightCard {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;

  @media screen and (min-width: 992px) {
    height: 250px;
    // max-height: 250px;
  }

  .card-header-bg {
    background: transparent;
  }

  .blog-text-card {
    padding: 1rem;
    margin: 1rem;
    flex: 1;

    .card-title {
      border-top: 2px solid #0000001a;
      border-bottom: 2px solid #0000001a;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
    }

    .blog-description {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 100%;
    }
  }
}

//blogCard details page
.blogCardContainer {
  width: 100%;
  height: auto;

  .bloCardLargeImg {
    background-size: contain;
    width: 100%;
    height: auto;
  }
}

// world section Know more
.ch {
  width: auto;

  @media screen and (min-width: 1401px) {
    width: 54ch;
  }
  @media (max-width: 786px) {
    width: 100%;
  }
}

.knowMoreBtn {
  width: 228px;
  height: 65px;
  text-align: center;
  font: normal normal normal 20px/24px Helvetica 75 Bold;
  letter-spacing: 0.6px;
  color: #ffffff;
  opacity: 1;
  background: #ce0024 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #ce002466;
  border-radius: 33px;
  border: none;
  opacity: 1;
  transition: 0.3s ease;
  &:hover {
    // filter: brightness(1.75);
    transition: 0.3s ease;
    color: black;
    background-color: #ff003f;
  }
}

//Footer
.left-leaf {
  // height: 431px;
  position: absolute;
  left: 0;
  top: 0;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    width: auto;
    height: 150px;
    position: absolute;
    bottom: 0;
    top: auto;
  }
}
.right-leaf {
  // height: 431px;
  right: 0;
  top: 0;
  position: absolute;
  @media screen and (max-width: 768px) and (min-width: 320px) {
    width: auto;
    height: 150px;
    position: absolute;
  }
}

.mobile-left-leaf {
  // height: 431px;
  width: 150px;
  @media (max-width: 768px) {
    flex: 0;
    align-items: flex-end;
    position: relative;
  }
}

.mobile-right-leaf {
  // height: 431px;
  width: 150px;
  @media (max-width: 1200px) {
    flex: 1;
    justify-content: flex-end;
    display: flex;
  }

  @media (max-width: 768px) {
    flex: 0;
    align-items: flex-end;
  }
}

.emailIdCol,
.phoneNumberCol {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.emailIcon {
  max-width: 2.818em !important;
}

.leaf-img.img-fluid {
  max-width: 13.636em;
}

.socialMedia {
  display: flex;
  // flex-direction: row;
  justify-content: center;

  .socialLinksItem {
    margin: 0 1em;
  }

  .emailPhoneContainer {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    padding: 2.727em 0;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      // position: relative;
      // z-index: 6;
    }

    .emailIcon,
    .phoneIcon {
      @media (max-width: 768px) {
        width: 5em;
      }
    }

    .emailCol,
    .phoneCol {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0.5em;

      @media (max-width: 768px) {
        padding: 0 8px;
        // margin: 16px auto;
        margin-bottom: 1em;
      }

      .phoneNumberCol,
      .emailIdCol {
        color: #ffff;
      }

      @media (max-width: 768px) {
        text-align: center;
        flex-direction: column;
      }
    }
  }

  .socialLinksContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.273em;
    position: relative;
    z-index: 6;

    @media (max-width: 768px) {
      justify-content: center;
      // margin: 32px auto;
    }
  }

  .socialLinksItem {
    .footerIcon {
      height: auto;
      max-width: 3.818em;
      width: 100%;
      @media (max-width: 768px) {
        width: 5em;
      }
    }
  }

  .policyContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      align-items: center;
      flex-direction: column;
    }

    span {
      color: white;
      white-space: nowrap;
      height: auto;
      padding: 0 4px;

      @media (max-width: 768px) {
        padding: 4px 8px;
      }
    }

    .footerBar {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}

//zipcode form
.errorMsg {
  font-style: "italic";
  color: "red";
  padding: "16px";
}

//FindStoreSection

.CyanBg {
  background-color: #62d398;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 1.818em 12px;

  @media (max-width: 768px) {
    // height: 1200px !important;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  @media (min-width: 768px) {
  }

  .main-row {
    height: 100%;
    @media (max-width: 992px) {
      flex-direction: column !important;
      height: auto;
    }
  }

  .starting-content {
    flex: 0;
    padding: 0;
    margin: 0;

    .roti-top {
      @media (max-width: 992px) {
        margin: auto;
        flex: 0;
        transform: translateY(-70px) rotate(90deg);
        height: 100%;
        width: 150px;
      }
    }
  }

  .middle-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .infinity,
    .infinity-white {
      height: 21px;
      width: 59px;
      margin: auto;
    }
  }

  .ending-content {
    flex: 0;
    padding: 0;
    margin: 0;

    // @media (max-width: 992px) {
    //   margin: auto;
    //   flex: 0;
    // }

    .rice-btm {
      @media (max-width: 992px) {
        margin: auto;
        flex: 0;
        transform: translateY(39px) rotate(90deg);
        height: 100%;
        width: 150px;
      }
    }
  }

  .zipcode-searchbar-form {
    display: flex;
    align-items: center;
    height: auto;
    justify-content: center;
    flex-direction: column;

    &:focus-visible {
      outline: none;
      border: none;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    .findStoreHomeForm {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-wrap: nowrap;
      max-width: 885px;

      @media screen and (max-width: 1240px) {
        flex-wrap: wrap;
      }
    }

    .find-store-searchbar {
      width: 100%;
      max-width: 885px;
      height: 65px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      border: none;
      padding: 0 32px;

      &:focus-visible {
        outline: none;
        border: none;
      }

      @media screen and (max-width: 1240px) {
        border-radius: 50px;
        margin: 24px;
      }

      &::placeholder {
        padding-left: 32px;
      }
    }

    .find-store-btn {
      background: #ce0024;
      width: 255px;
      height: 65px;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border: none;
      outline: none;
      color: #ffffff;

      &:hover {
        cursor: pointer;
        filter: brightness(1.75);
      }

      :focus-visible {
        outline: none;
        border: none;
      }

      .search-icon {
        position: absolute;
        transform: translateX(-30px);

        @media screen and (max-width: 1440px) {
          transform: translateX(-20px);
        }

        // @media (max-width:1240px) {
        //   transform: translateX(-24px);
        // }

        .search-icon-img {
          height: 16px;
          width: 16px;
        }
      }

      @media screen and (max-width: 1240px) {
        border-radius: 50px;
        margin: 24px;
      }
    }
  }
}

//pagination common styles
.paginationButtns {
  width: 80%;
  height: 70px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    height: 140px;
  }

  a {
    width: 61px;
    height: 59px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #ce0024;
    color: #ce0024;
    text-decoration: none !important;

    &:hover {
      cursor: pointer;
      background: #ce0024;
      color: white;
    }
  }
  .paginationActive {
    a {
      background: #ce0024;
      color: white !important;
    }
  }
}

.MainFooter a {
  color: #fff;
  transition: 0.3s ease;
  text-decoration: none;

  &:hover {
    color: #ce0024;
  }
}
@media (max-width: 834px) {
  .socialMedia .emailPhoneContainer {
    flex-direction: column;
  }
  .socialMedia .emailPhoneContainer .emailCol,
  .socialMedia .emailPhoneContainer .phoneCol {
    flex-direction: column;
    text-align: center;
  }
  .socialMedia .emailPhoneContainer .emailCol {
    margin-bottom: 1em;
  }
}
@media (max-width: 768px) {
  .mobile-left-leaf .left-leaf {
    top: 0;
  }
}
@media (max-width: 767px) {
  .mobile-left-leaf .left-leaf {
    bottom: 0;
    top: auto;
    z-index: 3;
  }
  .mobile-right-leaf .right-leaf {
    bottom: 22px;
    top: auto;
  }
  .leaf-img.img-fluid {
    max-width: 7.6em;
  }
}
@media (max-width: 480px) {
  .MainNavBar .NavbarItems .navbar-logo .navbar-brand-logo {
    max-width: 4.9em;
  }
  .MainContent {
    margin-top: 4.9em;
    margin-top: 7.2em;
  }
}
@media (max-width: 2560px) and (min-width: 1921px) {
  .MainContent {
    position: relative;
    margin-top: 9.333em;
  }
}
@media (max-width: 834px) {
  .nav-links::before {
    display: none;
  }
  .MainNavBar .NavbarItems .nav-menu.active {
    align-items: flex-start;
  }
  .MainNavBar .NavbarItems .nav-menu .nav-links {
    width: auto;
    padding: 0 0;
    margin-left: 1.154em;
  }
}

@media (max-width: 480px) {
  .MainNavBar .NavbarItems .navbar-logo .navbar-brand-logo {
    max-width: 5.5em;
  }
}
@media (max-width: 380px) {
  .MainNavBar .NavbarItems .navbar-logo .navbar-brand-logo {
    max-width: 4.9em;
  }
}
