@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.thankyousec {
  background-image: url("../../images/landing-page/thankbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  font-family: "Poppins", sans-serif;
  align-items: center;
  justify-content: center;
  .thankyou_wrapper {
    height: 70%;
    width: 70%;
    padding: 1em;
    border: 3px solid #e7c16d;
    border-radius: 4.688em;
  }
  .thankyou_content {
    height: 100%;
    border-radius: 4.688em;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
  .thankdesign {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .thanklogo {
    max-width: 8.438em;
  }
  .thank_heading {
    font-size: 3.375em;
    font-family: "Cinzel", serif;
    text-transform: uppercase;
    font-weight: 400;
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 0.343em;
    text-align: center;
  }
  .thankyoudesc {
    font-size: 1.25em;
    letter-spacing: 0.4px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 2em;
  }
  .thankbtn {
    font-size: 1.25em;
    letter-spacing: 0.4px;
    color: #cc121d;
    text-transform: uppercase;
  }
}
@media (max-width: 767px) {
  .thankyousec {
    .thankyou_wrapper {
      width: 95%;
    }
    .thank_heading {
      font-size: 2.375em;
      line-height: 1.5em;
    }
    .thankyoudesc {
      max-width: 95%;
      font-size: 1.4em;
    }
  }
}
