.winner_sec {
  position: relative;
  background-image: url("../../../images/Diwali/Golden-Jublee-banner-bg@2x.jpg");
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  .title {
    padding-top: 12em;
    margin-left: 7em;
    font-family: DVB-TTBhimaEN, Bold;
    color: white;
    h1 {
      font-size: 3.75em;
    }
  }
  .winner_names_outer {
    width: 69em;
    border-color: #ffd361;
    border-style: solid;
    border-width: 3px 3px 0 3px;
    border-radius: 1.875em;
    .winner_names {
      background-color: #aa0013;
      border-radius: 1.25em;
      margin: 0.7em;
      .scroll_desc {
        text-align: center;
        color: #fff;
        padding-top: 1em;
        font-size: 1.3em;
      }
      .week_dropdown {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3.5em;

        .dropdown_content {
          position: relative;
          .week {
            cursor: pointer;
            width: 9.5em;
            padding-right: 1.5em;
            height: 3.3em;
            margin-top: 1.625em;
            border-radius: 0.7em;
            font-size: 1.125em;
            font-weight: 500;
            text-align: center;
            letter-spacing: 0.5px;
            color: #a60c1b;
            border: 3px solid #dcb35b;
            -webkit-appearance: none;
          }
          .downarrow {
            cursor: pointer;
            position: absolute;
            top: 51%;
            right: 12%;
            width: 1.4em;
          }
        }
      }
      .name_section {
        -webkit-appearance: none;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        height: 0;
        transition: transform 0s ease-out; // note that we're transitioning transform, not height!
        transform-origin: top;
        transform: scaleY(0); // implicit, but good to specify explicitly
        overflow: auto;
        &::-webkit-scrollbar-thumb {
          background-color: #fff;
          cursor: pointer;
          -webkit-appearance: none;
        }

        .name {
          font-family: Poppins, Medium;
          position: relative;
          width: 20em;
          margin-bottom: 2.7em;
          img {
            max-width: 100%;
            padding-left: 1.5em;
          }
          .name_disp {
            width: 7.5em;
            position: absolute;
            line-height: 22px;
            // right: 20%;
            left: 6em;
            top: 33%;
            color: #b70014;
            // font-family: Poppins;
            font-size: 1.2em;
            font-weight: 500;
            text-transform: capitalize;
          }
        }
      }
      .name_section.active {
        height: auto;
        max-height: 52vh;
        transform: scaleY(1);
      }
    }
  }

  //   .gold_winner_section {
  //     position: relative;
  //   }
  .goldcoin {
    position: absolute;
    top: 36.5%;
    right: 25%;
    z-index: 2;
    overflow: hidden;
    border-radius: 50%;
    &::after {
      content: "";
      width: 1.875em;
      height: 12.5em;
      position: absolute;
      left: 0px;
      top: 0;
      margin-top: -20px;
      background: #fff;
      opacity: 0.6;
      filter: blur(10px);
      animation: goldcoin 3s infinite;
      animation-delay: 0s;
      transform-origin: top;
    }
    @keyframes goldcoin {
      0% {
        transform: translate(-12.5em) rotate(-45deg);
      }
      50% {
        transform: translateX(6.25em) rotate(-45deg);
      }
      100% {
        transform: translate(-12.5em) rotate(-45deg);
      }
    }
    img {
      max-width: 100%;
    }
  }

  .gold_winner {
    position: absolute;
    top: 47%;
    right: 22.3%;
    z-index: 2;
    .goldwinner_name {
      position: relative;
      text-align: center;
      img {
        max-width: 100%;
      }
      p {
        position: absolute;
        top: 15%;
        left: 14%;
        width: 7.75em;
        line-height: 22px;
        color: #b70014;
        font-family: Poppins;
        font-size: 1.25em;
        font-weight: 500;
      }
    }
  }

  .shilpa_image {
    position: absolute;
    right: -6%;
    top: 36vh;
    // top: 80vh;
    z-index: 0;
    img {
      max-width: 100%;
    }
  }
}

@media (max-width: 2560px) {
  .winner_sec {
    .my_container {
    }
    .title {
      padding-top: 13em;
      margin-left: 7em;
      font-family: DVB-TTBhimaEN, Bold;
      color: white;
      h1 {
        font-size: 5em;
      }
    }

    .winner_names_outer {
      width: 70em;
      .winner_names {
        .week_dropdown {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 4em;
          .dropdown_content {
            .week {
              font-size: 1.2em;
            }
            .downarrow {
              top: 53%;
            }
          }
        }

        .name_section {
          .name {
            position: relative;
            width: 22.5em;
            margin-bottom: 3.5em;
            img {
              max-width: 100%;
            }
            .name_disp {
              width: 7.3em;
              left: 5em;
              top: 34%;
              font-size: 1em;
            }
          }
        }
      }
    }

    .goldcoin {
      position: absolute;
      top: 43em;
      right: 33em;

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    .gold_winner {
      position: absolute;
      top: 50em;
      right: 30em;
      .goldwinner_name {
        position: relative;
        text-align: center;
        img {
          max-width: 100%;
        }
        p {
          position: absolute;
          top: 17%;
          left: 10%;
          width: 7.75em;
          line-height: 22px;
          color: #b70014;
          font-size: 1.25em;
          font-weight: 500;
        }
      }
    }

    .shilpa_image {
      position: absolute;
      right: 0;
      top: 42em;
      //   bottom: 0;
      width: 100%;
      max-width: 40em;
    }
  }
}

@media (max-width: 1920px) {
  .winner_sec {
    position: relative;
    background-image: url("../../../images/Diwali/Golden-Jublee-banner-bg@2x.jpg");
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;

    .title {
      padding-top: 12em;
      margin-left: 5em;
      font-family: DVB-TTBhimaEN, Bold;
      color: white;
      h1 {
        font-size: 3.75em;
      }
    }
    .winner_names_outer {
      width: 69em;
      .winner_names {
        background-color: #aa0013;
        margin: 0.7em 0.7em 0 0.7em;
        .week_dropdown {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 3.5em;
          .dropdown_content {
            .week {
              width: 9.5em;
              height: 3.3em;
              border-radius: 0.7em;
              font-size: 1.125em;
              font-weight: 500;
              text-align: center;
              letter-spacing: 0.5px;
              color: #a60c1b;
              border: 3px solid #dcb35b;
            }
            .downarrow {
            }
          }
        }
        .name_section {
          .name {
            position: relative;
            width: 20em;
            margin-bottom: 2.7em;
            img {
              max-width: 100%;
            }
          }
          .name {
            .name_disp {
              width: 7.5em;
              position: absolute;
              line-height: 20px;
              // right: 22%;
              top: 35%;
              // font-size: 1em;
            }
          }
        }
      }
    }
    .goldcoin {
      position: absolute;
      top: 25em;
      right: 28.5em;
      img {
        max-width: 100%;
      }
    }

    .gold_winner {
      position: absolute;
      top: 32em;
      right: 25em;
      .goldwinner_name {
        position: relative;
        text-align: center;
        img {
          max-width: 100%;
        }
        p {
          position: absolute;
          top: 15%;
          left: 14%;
          width: 7.75em;
          line-height: 22px;
          color: #b70014;
          font-size: 1.25em;
          font-weight: 500;
        }
      }
    }
    .shilpa_image {
      position: absolute;
      right: 0;
      top: 25.5em;
      width: 100%;
      max-width: 35em;
    }
  }
}

@media (max-width: 1600px) {
  .winner_sec {
    .title {
      margin-left: 5em;
      h1 {
        font-size: 3.7em;
      }
    }
    .winner_names_outer {
      width: 65em;
      .winner_names {
        .week_dropdown {
          margin-bottom: 2.7em;
        }

        .name_section {
          .name {
            position: relative;
            width: 20em;
            margin-bottom: 2em;
            img {
              max-width: 100%;
            }
            .name_disp {
              width: 7.5em;
              left: 6em;
              // top: 34%;
              font-size: 1.15em;
            }
          }
        }
      }
    }

    .goldcoin {
      top: 23em;
      right: 25.5em;

      img {
        width: 100%;
        max-width: 7em;
      }
    }

    .gold_winner {
      position: absolute;
      top: 31em;
      right: 23em;
      .goldwinner_name {
        position: relative;
        text-align: center;
        img {
          width: 100%;
          max-width: 12em;
        }
        p {
          position: absolute;
          top: 12%;
          left: 11%;
          width: 7.75em;
          line-height: 22px;
          color: #b70014;
          font-size: 1.25em;
          font-weight: 500;
        }
      }
    }

    .shilpa_image {
      width: 100%;
      max-width: 32em;
      position: absolute;
      top: 25em;
      right: 0;
      //   bottom: 0;

      img {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1536px) {
  .winner_sec {
    .title {
      padding-top: 10em;
      margin-left: 6em;
      h1 {
        font-size: 3.7em;
      }
    }
    .winner_names_outer {
      width: 65em;
      .winner_names {
        .week_dropdown {
          margin-bottom: 3em;
        }
        .name_section {
          overflow: auto;
          .name {
            position: relative;
            width: 20em;
            margin-bottom: 2em;
            img {
              max-width: 100%;
            }
            .name_disp {
              width: 7.5em;
              // right: 22%;
              left: 7em;
              // top: 31%;
              line-height: 20px;
              font-size: 1em;
            }
          }
        }
      }
    }

    .goldcoin {
      top: 19em;
      right: 23.5em;

      img {
        width: 100%;
        max-width: 6.5em;
      }
    }

    .gold_winner {
      position: absolute;
      top: 26em;
      right: 21em;
      .goldwinner_name {
        position: relative;
        text-align: center;
        img {
          width: 100%;
          max-width: 11em;
        }
        p {
          position: absolute;
          top: 11%;
          left: 7%;
          width: 7.75em;
          line-height: 22px;
          color: #b70014;
          font-size: 1.25em;
          font-weight: 500;
        }
      }
    }

    .shilpa_image {
      width: 100%;
      max-width: 30em;
      top: 20.5em;

      img {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1440px) {
  .winner_sec {
    overflow: hidden;
    .winner_names_outer {
      width: 65em;
      .winner_names {
        .week_dropdown {
          margin-bottom: 3em;
        }

        .name_section {
          .name {
            margin-bottom: 2.5em;
            .name_disp {
              right: 22%;
              // top: 32%;
              line-height: 18px;
            }
          }
        }
      }
    }
    .goldcoin {
      top: 28em;
      right: 25.5em;

      img {
        width: 100%;
        max-width: 7em;
      }
    }

    .gold_winner {
      position: absolute;
      top: 36em;
      right: 23em;
      .goldwinner_name {
        img {
          width: 100%;
          max-width: 12em;
        }
        p {
          top: 8%;
          left: 10%;
          line-height: 20px;
        }
      }
    }

    .shilpa_image {
      width: 41em;
      right: 0;
      top: 30em;
      //   bottom: 0;
      width: 100%;
      max-width: 33em;
    }
  }
}

@media (max-width: 1367px) {
  .winner_sec {
    overflow: hidden;

    .goldcoin {
      top: 22em;
      right: 22em;

      img {
        width: 100%;
        max-width: 7em;
      }
    }

    .gold_winner {
      position: absolute;
      top: 30em;
      right: 20em;
      .goldwinner_name {
        img {
          width: 100%;
          max-width: 11em;
        }
        p {
          font-size: 1.2em;
          position: absolute;
          top: 5%;
          left: 8%;
          line-height: 21px;
        }
      }
    }
    .shilpa_image {
      width: 100%;
      max-width: 29em;
      position: absolute;
      right: 0;
      top: 25em;
      //   bottom: 0;
    }
  }
}

@media (max-width: 1280px) {
  .winner_sec {
    .winner_names_outer {
      width: 65em;
      .winner_names {
        .week_dropdown {
          margin-bottom: 3em;
        }

        .name_section {
          .name {
            margin-bottom: 1.5em;
            .name_disp {
              right: 22%;
              // top: 32%;
              line-height: 17px;
            }
          }
        }
      }
    }

    .goldcoin {
      top: 30em;
      right: 22em;

      img {
        width: 100%;
        max-width: 7em;
      }
    }

    .gold_winner {
      position: absolute;
      top: 38em;
      right: 20em;
      .goldwinner_name {
        img {
          width: 100%;
          max-width: 11em;
        }
        p {
          font-size: 1.2em;
          position: absolute;
          top: 5%;
          left: 8%;
          line-height: 21px;
        }
      }
    }
    .shilpa_image {
      width: 40em;
      position: absolute;
      right: 0;
      top: 33em;
      //   bottom: 0;
    }
  }
}

@media (max-width: 1024px) {
  .winner_sec {
    .winner_names_outer {
      width: 65em;
      .winner_names {
        .name_section {
          .name {
            margin-bottom: 1.5em;
            .name_disp {
              right: 23%;
              // top: 32%;
              line-height: 15px;
            }
          }
        }
      }
    }

    .goldcoin {
      top: 37em;
      right: 26em;

      img {
        width: 100%;
        max-width: 7.5em;
      }
    }

    .gold_winner {
      position: absolute;
      top: 45em;
      right: 24em;
      .goldwinner_name {
        img {
          width: 100%;
          max-width: 11em;
        }
        p {
          font-size: 1.2em;
          position: absolute;
          top: 5%;
          left: 8%;
          line-height: 16px;
        }
      }
    }
    .shilpa_image {
      max-width: 34em;
      position: absolute;
      right: 0;
      top: 38em;
      //   bottom: 0;
    }
  }
}

@media (max-width: 992px) {
  .winner_sec {
    overflow-y: auto;
    .title {
      margin: 0;
      width: 100%;
      padding-top: 15em;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 5em;
      }
    }
    .winner_names_outer {
      width: 100%;
      .winner_names {
        .week_dropdown {
          margin-bottom: 4em;
          .dropdown_content {
            .week {
              font-size: 1.2em;
            }
          }
        }

        .name_section {
          .name {
            position: relative;
            width: 22em;
            margin-bottom: 3em;
            img {
              max-width: 100%;
            }
            .name_disp {
              width: 7.5em;
              position: absolute;
              line-height: 14px;
              right: 23%;
              // top: 33%;
              color: #b70014;
              font-size: 1.2em;
              font-weight: 500;
            }
          }
        }
        .name_section.active {
          max-height: 35vh;
        }
      }
    }

    .goldcoin {
      position: absolute;
      top: 78em;
      right: 50em;

      img {
        max-width: 9em;
      }
    }

    .gold_winner {
      position: absolute;
      top: 88em;
      right: 47em;
      .goldwinner_name {
        position: relative;
        text-align: center;
        img {
          max-width: 15em;
        }
        p {
          position: absolute;
          top: 14%;
          left: 15%;
          width: 7.5em;
          line-height: 20px;
          color: #b70014;
          font-size: 1.5em;
          font-weight: 500;
        }
      }
    }

    .shilpa_image {
      max-width: 40em;
      position: absolute;
      right: 20em;
      top: 80em;
      //   bottom: 0;
    }
  }
}

@media (max-width: 600px) {
  .winner_sec {
    .title {
      padding-top: 10em;
      margin: 0;
      h1 {
        font-size: 2.4em;
      }
    }
    .winner_names_outer {
      width: 100%;
      .winner_names {
        padding-bottom: 32em;
        position: relative;
        .scroll_desc {
          font-size: 1.3em;
        }
        .name_section {
          .name {
            position: relative;
            width: 100%;
            max-width: 49%;
            height: 6em;
            img {
              max-width: 100%;
              padding-left: 0;
            }
            .name_disp {
              width: 100%;
              max-width: 6em;
              line-height: 17px;
              left: 5em;
              top: 2.25em;
              font-size: 1.3em;
            }
          }
        }
        .name_section.active {
          max-height: 32vh;
        }
        .shilpa_image_mob {
          position: absolute;
          bottom: 0;
          right: -3em;
          width: 100%;
          max-width: 37em;
          img {
            max-width: 100%;
          }
        }
      }
    }

    .goldcoin {
      top: 57.5em;
      right: auto;
      left: 7.5em;

      img {
        width: 100%;
        max-width: 5em;
      }
    }

    .gold_winner {
      top: 63.5em;
      right: auto;
      left: 4em;
      .goldwinner_name {
        position: relative;
        text-align: center;
        img {
          width: 100%;
          max-width: 11em;
        }
        p {
          position: absolute;
          top: 10%;
          left: 12%;
          width: 7em;
          line-height: 15px;
          color: #b70014;
          font-size: 1.25em;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .winner_sec {
    .winner_names_outer {
      .winner_names {
        .name_section {
          .name {
            .name_disp {
              left: 3.6em;
              top: 2em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .winner_sec {
    .winner_names_outer {
      .winner_names {
        .name_section {
          .name {
            .name_disp {
              left: 3.6em;
              top: 2em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .winner_sec {
    .winner_names_outer {
      .winner_names {
        .name_section {
          .name {
            .name_disp {
              left: 4em;
              top: 2.1em;
              font-size: 1.1em;
            }
          }
        }
      }
    }
    .goldcoin {
      top: 53em;
      right: auto;
      left: 7em;

      img {
        width: 100%;
        max-width: 5em;
      }
    }

    .gold_winner {
      top: 59em;
      right: auto;
      left: 4em;
      .goldwinner_name {
        position: relative;
        text-align: center;
        img {
          width: 100%;
          max-width: 11em;
        }
        p {
          position: absolute;
          top: 10%;
          left: 12%;
          width: 7em;
          line-height: 15px;
          color: #b70014;
          font-size: 1.25em;
          font-weight: 500;
        }
      }
    }
  }
}

@media (max-width: 365px) {
  .winner_sec {
    .winner_names_outer {
      .winner_names {
        .name_section {
          .name {
            .name_disp {
              top: 1.6em;
              font-size: 1.1em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 340px) {
  .winner_sec {
    .winner_names_outer {
      .winner_names {
        .name_section {
          .name {
            .name_disp {
              left: 3.5em;
              line-height: 1em;
              font-size: 1em;
              top: 35%;
            }
          }
        }
      }
    }
  }
}
