@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
// font-family: 'Cinzel', serif;
// font-family: 'Poppins', sans-serif;
$color-00C4A7: #00c4a7;
$color-CC121D: #cc121d;
$color-AE0013: #ae0013;
.loader_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.diwali_page_main {
  .swiper-container {
    min-height: auto;
  }
  .swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .error {
    color: #9c0000;
    font-weight: 500;
    font-size: 1.1em;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
  }
  input:focus,
  select:focus {
    outline: none;
  }
  font-family: "Poppins", sans-serif;
  img {
    object-fit: cover;
  }
  p,
  figure {
    margin: 0;
    padding: 0;
  }
  .sec_heading {
    font-size: 3.125em;
    font-family: "Cinzel", serif;
    color: #1a1818;
    letter-spacing: 1px;
    font-weight: 400;
  }
  .para_desc {
    font-size: 1.35em;
    color: #1a1818;
    letter-spacing: 0.4px;
    line-height: 1.9em;
    font-weight: 400;
    .note_span {
      font-weight: bold;
    }
    .here_link {
      font-weight: bold;
      cursor: pointer;
      color: $color-CC121D;
      text-decoration: underline;
    }
  }
  .curved_design_logo {
    width: 100%;
    max-width: 3.625em;
  }
}

.diwali_header {
  height: 9em;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  padding-left: 9.5em;
  padding-right: 9.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  .terms_anchor {
    color: #1a1818;
    text-decoration: none;
  }
  .hamburger_icon {
    display: none;
  }
  .laxmilogo {
    max-width: 8.438em;
  }
  .nav_flex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .nav_links {
    margin-bottom: 0;
    padding-left: 0;
    li {
      position: relative;
      display: inline-block;
      font-size: 1.375em;
      letter-spacing: 0.44px;
      margin-right: 2.636em;
      font-weight: 500;
      color: #1a1818;
      cursor: pointer;
      &:hover {
      }
      a {
        color: #1a1818;
        text-decoration: none;
        text-transform: uppercase;
      }
      span {
        color: #1a1818;
      }
      .white_cta {
        color: #fff;
      }
      .button_background {
        position: absolute;
        left: -2em;
        top: -0.9em;
        bottom: 0;
        right: 0;
        z-index: -1;
        max-width: 8.563em;
      }
    }
  }
  .social_icons {
    a {
      margin-right: 1.438em;
    }
    img {
      max-width: 3.125em;
      transition: 0.4s transform;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}

@media (min-width: 2560px) {
  .diwali_page_main {
    .my_container {
      max-width: 2076px;
    }
  }
}
@media (max-width: 1919px) {
  .diwali_page_main {
    .my_container {
      max-width: 1500px;
    }
  }
}

@media (max-width: 1600px) {
  .diwali_page_main {
    .my_container {
      max-width: 100%;
    }
  }
}
@media (max-width: 1580px) {
  .diwali_page_main {
    .my_container {
      max-width: 1300px;
    }
  }
}

@media (max-width: 1540px) {
  .diwali_page_main {
    font-size: 11px;
  }
}
@media (max-width: 1440px) {
  .diwali_page_main {
    font-size: 10.5px;
  }
}
@media (max-width: 1367px) {
  .diwali_page_main {
    font-size: 10px;
    .my_container {
      max-width: 1168px;
    }
  }
}
@media (max-width: 1280px) {
  .diwali_page_main {
    font-size: 9.5px;
  }
}
@media (max-width: 991px) {
  .diwali_page_main {
    .my_container {
      max-width: 100%;
    }
  }
  .diwali_header {
    padding-left: 1.5em;
    padding-right: 1.5em;
    border-radius: 0;
    .nav_flex {
      flex-wrap: wrap;
      margin-left: 0em;
      .nav_links {
        li {
          margin: 0 1em;
          font-size: 1.4em;
          margin-bottom: 1em;
          a {
            text-transform: capitalize;
          }
        }
      }
    }
    .social_icons {
      display: none;
    }
  }
}
@media (max-width: 834px) {
  .diwali_page_main {
    .sec_heading {
      font-size: 2.4em;
      letter-spacing: 0.48px;
      max-width: 80%;
      margin: 0 auto;
    }
    .para_desc {
      font-size: 1.4em;
      letter-spacing: 0.28px;
    }
  }
}
@media (max-width: 767px) {
  .diwali_header {
    height: 7.7em;
    .terms_anchor {
      color: #fff;
    }
    .hamburger_icon {
      display: block;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5em;
      position: absolute;
      z-index: 1;
    }
    .laxmilogo_wrapper {
      flex-grow: 1;
      text-align: left;
    }
    .laxmilogo {
      max-width: 6.6em;
    }
    .nav_flex {
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      background-color: $color-AE0013;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 1.8em;
      right: -100%;
      transition: 0.3s left;
      .nav_links {
        margin-bottom: 5.6em;
        li {
          display: block;
          margin-right: 0;
          font-size: 1.8em;
          font-weight: 400;
          text-transform: uppercase;
          margin-top: 2.333em;
          margin: 0 1em 2.333em !important;

          a {
            color: #fff;
          }
          &:first-child {
            margin-top: 0;
          }
          span {
            color: #fff !important;
          }
          .white_cta {
            color: #fff;
          }
          .button_background {
            display: none;
          }
        }
      }
    }
    .nav_flex.active {
      right: -25%;
    }
  }
}
